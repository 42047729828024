import { useState } from "react"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Check, CreditCard, Building } from "lucide-react"
import { Badge } from "@/components/ui/badge"
import { useAuth } from "@/context/AuthContext"
import PaymentButton from "./PaymentButton"

export default function PaymentPlanSelector() {
  const { user } = useAuth()

  const [selectedPlan, setSelectedPlan] = useState<"token" | "enterprise">(
    "token"
  )
  const [customPrice, setCustomPrice] = useState<string>("5")
  const [showEnterpriseDialog, setShowEnterpriseDialog] = useState(false)

  const calculateTokens = (price: number) => price * 1000

  const handlePriceChange = (value: string) => {
    const numericValue = value.replace(/[^0-9.]/g, "")
    const price = parseFloat(numericValue)

    if (price > 200) {
      setShowEnterpriseDialog(true)
      return
    }

    if (!isNaN(price) && price >= 5) {
      setCustomPrice(numericValue)
    } else {
      setCustomPrice("5")
    }
  }

  const handleContactSales = () => {
    const googleFormUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLSdMPPDaK7PGa9ugtvxwW8F5mW2RaGX1RCjzaYYs0QKXnEK_TQ/viewform?usp=header"
    window.open(googleFormUrl, "_blank", "noopener,noreferrer")
  }

  return (
    <div className="flex flex-col gap-5 w-full max-w-4xl mx-auto p-4">
      {/* Current Plan Section */}
      <div className="border-2 p-5 rounded-lg bg-white hover:shadow-xl duration-300">
        <div className="flex justify-between items-center mb-8">
          <div>
            <div className="text-sm text-muted-foreground mb-1">
              Modify your plan
            </div>
            <h1 className="text-3xl font-bold">My plan</h1>
          </div>
          <Badge
            variant="outline"
            className="bg-green-100 text-green-800 border-green-300"
          >
            Operational
          </Badge>
        </div>

        <div className="mb-8">
          <div className="flex items-center space-x-4 mb-2">
            <div>
              Current plan: <span className="font-semibold">Researcher</span>
            </div>
            <Button variant="outline" size="sm">
              Manage Plan
            </Button>
          </div>
          <div className="flex items-center justify-between">
            <div>
              Current limit: <span className="font-semibold">40 / 1,000</span>
            </div>
            <div className="text-sm text-muted-foreground">Auto-upgrade</div>
          </div>
          <div className="text-sm text-muted-foreground">
            Upgrade to the next plan automatically when you reach your limit
          </div>
        </div>
      </div>

      <h1 className="text-3xl font-bold text-center mb-3">Choose Your Plan</h1>

      <div className="grid md:grid-cols-2 gap-8">
        {/* Token-based Plan Card */}
        <Card
          onClick={() => setSelectedPlan("token")}
          className={`relative overflow-hidden transition-all duration-300 flex flex-col ${
            selectedPlan === "token" ? "ring-2 ring-primary" : "hover:shadow-xl"
          }`}
        >
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <CreditCard className="h-6 w-6" />
              Token-based Plan
            </CardTitle>
            <CardDescription>Pay for what you use</CardDescription>
          </CardHeader>
          <CardContent className="flex-grow flex flex-col justify-center items-center">
            <div className="space-y-2 w-full max-w-[300px]">
              <Label htmlFor="price" className="w-full block text-base">
                Custom price
              </Label>
              <div className="relative">
                <span className="absolute left-3 top-1/2 -translate-y-1/2">
                  $
                </span>
                <Input
                  id="price"
                  type="number"
                  value={customPrice}
                  onChange={(e) => setCustomPrice(e.target.value)}
                  onBlur={(e) => handlePriceChange(e.target.value)}
                  className="pl-7 h-12"
                  placeholder="Enter amount"
                />
              </div>
            </div>
          </CardContent>
          <div className="flex flex-col items-stretch gap-4 bg-muted/50 p-6">
            <div className="flex justify-between items-baseline">
              <div className="text-3xl font-bold">
                ${customPrice}
                <span className="text-lg font-normal text-muted-foreground ml-1">
                  /month
                </span>
              </div>
              <div className="text-right italic">
                <span className="text-lg">
                  {calculateTokens(parseFloat(customPrice)).toLocaleString()}
                </span>
                <span className="text-xl font-semibold text-muted-foreground ml-2">
                  tokens
                </span>
              </div>
            </div>
            <PaymentButton
              userName={user?.displayName}
              phoneNumber={user?.phoneNumber}
              userEmail={user?.email}
              amount={Number(customPrice)}
              currency={"INR"}
              receiptId={"recipt1"}
              name={"Select Plan"}
            />
          </div>
          {selectedPlan === "token" && (
            <div className="absolute top-2 right-2 bg-primary text-primary-foreground rounded-full p-1">
              <Check className="h-4 w-4" />
            </div>
          )}
        </Card>

        {/* Enterprise Plan Card */}
        <Card
          onClick={() => setSelectedPlan("enterprise")}
          className={`relative overflow-hidden transition-all duration-300 flex flex-col ${
            selectedPlan === "enterprise"
              ? "ring-2 ring-primary"
              : "hover:shadow-xl"
          }`}
        >
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Building className="h-6 w-6" />
              Enterprise Plan
            </CardTitle>
            <CardDescription>Custom solution for your business</CardDescription>
          </CardHeader>
          <CardContent>
            <ul className="space-y-2">
              <li className="flex items-center gap-2">
                <Check className="h-4 w-4 text-green-500" />
                <span>Unlimited tokens</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="h-4 w-4 text-green-500" />
                <span>Priority support</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="h-4 w-4 text-green-500" />
                <span>Custom integrations</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="h-4 w-4 text-green-500" />
                <span>Dedicated account manager</span>
              </li>
            </ul>
          </CardContent>
          <div className="flex flex-col items-stretch gap-4 bg-muted/50 p-6 mt-auto">
            <div className="text-3xl font-bold">
              Custom
              <span className="text-lg font-normal text-muted-foreground">
                {" "}
                pricing
              </span>
            </div>
            <Button
              className="w-full justify-center py-6 text-lg font-semibold"
              onClick={handleContactSales}
            >
              Contact Sales
            </Button>
          </div>
          {selectedPlan === "enterprise" && (
            <div className="absolute top-2 right-2 bg-primary text-primary-foreground rounded-full p-1">
              <Check className="h-4 w-4" />
            </div>
          )}
        </Card>
      </div>

      {/* Enterprise Dialog */}
      <Dialog
        open={showEnterpriseDialog}
        onOpenChange={setShowEnterpriseDialog}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Upgrade to Enterprise Plan</DialogTitle>
            <DialogDescription>
              For purchases over $200, we recommend our Enterprise Plan which
              includes unlimited tokens, priority support, and a dedicated
              account manager.
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-end gap-4 mt-4">
            <Button
              variant="outline"
              onClick={() => setShowEnterpriseDialog(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setSelectedPlan("enterprise")
                setShowEnterpriseDialog(false)
                handleContactSales()
              }}
            >
              View Enterprise Plan
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
