import { AppSidebar } from "@/components/app-sidebar"
import CaseList from "@/components/CaseList"
import { ChatDrawer } from "@/components/ChatDrawer"
import { DynamicBreadcrumb } from "@/components/DynamicBreadcrumb"
import { Toaster } from "@/components/ui/sonner"

import { Separator } from "@/components/ui/separator"
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from "@/components/ui/sidebar"
import Case from "@/pages/Case"
import { CassetteTapeIcon, Slash } from "lucide-react"
import { Navigate, Route, Routes } from "react-router-dom"
import { Measure } from "@/pages/Measure"
import Research from "@/pages/Research"
import { SettingPage } from "@/pages/SettingsPage"
import RootCanalMeasurements from "@/pages/ToothMeasure"
import ViewLibrary from "@/pages/ViewLibrary"
import ClinicalStudy from "@/pages/ClinicalStudy"
import TodoList from "@/components/TodoList"
import PaymentPlanSelector from "@/components/Price"
import MedicalVoiceChat from "@/components/MedicalVoiceChat"

export default function Page() {
  return (
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset>
        <header className="flex h-16 shrink-0 items-center gap-2">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <DynamicBreadcrumb />
          </div>
        </header>
        <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
          <div className="min-h-[100vh] flex-1 rounded-xl bg-muted/50 md:min-h-min ">
            <Routes>
              <Route path="/" element={<Navigate replace to="/cases" />} />
              <Route path="/cases" element={<CaseList />} />
              <Route path="/cases/:id" element={<Case />} />
              <Route path="/analyze/" element={<Measure />} />

              <Route path="/research" element={<Research />} />
              <Route path="/research/view-library" element={<ViewLibrary />} />
              <Route path="/research/papers" element={<ViewLibrary />} />
              <Route path="/consult" element={<MedicalVoiceChat />} />

              <Route
                path="/research/studies"
                element={<ClinicalStudy />}
              />
              <Route path="/collaborative-tools" element={<TodoList />} />
              <Route path="/collaborative-tools/notes" element={<TodoList />} />
              <Route path="/research/:id" element={<Research />} />
              {/* <Route path="/settings" element={<SettingPage />} />
              <Route path="/settings/:id" element={<SettingPage />} /> */}
              <Route
                path="/settings/billing"
                element={<PaymentPlanSelector />}
              />
              <Route path="/case" element={<Case />} />
              <Route path="/rootcanal" element={<RootCanalMeasurements />} />
            </Routes>
          </div>
          {/* <Case /> */}
          <ChatDrawer />
          <Toaster />
        </div>
      </SidebarInset>
    </SidebarProvider>
  )
}
