import { ChevronRight, FileText, ImageIcon, Scan, type LucideIcon } from "lucide-react"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible"
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/ui/sidebar"
import { Link, useLocation } from "react-router-dom"
import { Button } from "./ui/button"

export function NavMain({
  items,
}: {
  items: {
    title: string
    url: string
    icon: LucideIcon
    isActive?: boolean
    items?: {
      title: string
      url: string
    }[]
  }[]
}) {
  const router = useLocation().pathname

  return (
    <SidebarGroup>
      <SidebarGroupLabel>Platform</SidebarGroupLabel>
      <SidebarMenu>
        {items.map((item) => {
          const isActive = router.includes(item.url)

          return (
            <Collapsible
              key={item.title}
              asChild
              open={isActive}
              className="transition-all duration-300 ease-in-out"
            >
              <SidebarMenuItem className={isActive ? "bg-sidebar-accent" : ""}>
                <SidebarMenuButton asChild tooltip={item.title}>
                  <Link to={item.url}>
                    <item.icon />
                    <span className={isActive ? "font-semibold" : ""}>
                      {item.title}
                    </span>
                  </Link>
                </SidebarMenuButton>
                {item.items?.length ? (
                  <>
                    <CollapsibleTrigger asChild>
                      <SidebarMenuAction className="data-[state=open]:rotate-90">
                        <ChevronRight />
                        <span className="sr-only">Toggle</span>
                      </SidebarMenuAction>
                    </CollapsibleTrigger>
                    <CollapsibleContent
                      className="transition-all duration-300 ease-in-out overflow-hidden"
                    >
                      <SidebarMenuSub>
                        {item.items?.map((subItem) => {
                          const isSubItemActive = router === subItem.url

                          return (
                            <SidebarMenuSubItem key={subItem.title}>
                              <SidebarMenuSubButton asChild>
                                <Link to={subItem.url}>
                                  <span
                                    className={
                                      isSubItemActive ? "font-semibold" : ""
                                    }
                                  >
                                    <div className="flex flex-col gap-1">
                                      {/* <Button variant="ghost" className="justify-start gap-2">
                                        <Scan className="h-4 w-4" /> */}
                                      {subItem.title}
                                      {/* </Button> */}

                                    </div>

                                  </span>
                                </Link>
                              </SidebarMenuSubButton>
                            </SidebarMenuSubItem>
                          )
                        })}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </>
                ) : null}
              </SidebarMenuItem>
            </Collapsible>
          )
        })}
      </SidebarMenu>
    </SidebarGroup>
  )
}
