import React, { useRef, useEffect, useState } from 'react';
import {
  Stage,
  Layer,
  Image as KonvaImage,
  Line,
  Text,
  Group,
  Rect,
} from 'react-konva';
import useImage from 'use-image';

interface Result {
  segment?: {
    url: string;
  };
  url?: string;
  measurements: {
    bounding_box: {
      center: [number, number];
      width: number;
      height: number;
    };
    [key: string]: any; // Allow for flexible properties
  };
}

interface Prediction {
  class: string;
  confidence: number;
  sensitivity: number;
  bounding_box: {
    center: [number, number];
    width: number;
    height: number;
  };
}

interface AnomalyResults {
  predictions: Prediction[];
}

interface KonvaCanvasProps {
  file: File;
  results: Result[]; // Updated to reflect the nested structure
  anomalyResults: AnomalyResults;
  fileUrl: string;
  aiSettings: {
    cariesDetection: boolean;
    boneDensityAnalysis: boolean;
    rootCanalIdentification: boolean;
    sensitivity: number;
  };
  tool: 'pen' | 'eraser';
}

export function KonvaCanvas({
  file,
  results,
  aiSettings,
  tool,
  fileUrl,
  anomalyResults,
}: KonvaCanvasProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [image] = useImage(imageURL || '');
  const [resultImages, setResultImages] = useState<(HTMLImageElement | null)[]>([]);
  const [hoveredSegment, setHoveredSegment] = useState<number | null>(null);
  const [hoveredAnomaly, setHoveredAnomaly] = useState<string | null>(null);

  // Image dimensions and position
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  // Scaling factors for coordinates
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);

  // Load the base image from the file
  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImageURL(e.target?.result as string);
        console.log('Base image loaded:', e.target?.result);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  // Load the result images from the results array
  useEffect(() => {
    const loadImages = async () => {
      const chunkSize = 5;
      const loadChunk = async (chunk: Result[], startIndex: number) => {
        return Promise.all(
          chunk.map((result, index) => {
            return new Promise<HTMLImageElement | null>((resolve) => {
              const img = new Image();
              const imgUrl = result?.segment?.url || result?.url;
              if (!imgUrl) {
                console.error(`No URL found for result at index ${startIndex + index}`, result);
                resolve(null); // Resolve with null to indicate failure
                return;
              }
              img.crossOrigin = 'anonymous'; // Ensure CORS is handled
              img.src = imgUrl;
              img.onload = () => {
                console.log(`Segment image loaded: ${img.src}, width: ${img.width}, height: ${img.height}`);
                resolve(img);
              };
              img.onerror = (error) => {
                console.error(`Failed to load segment image: ${img.src}`, error);
                resolve(null); // Resolve with null to handle gracefully
              };
            });
          })
        );
      };

      let loadedImages: (HTMLImageElement | null)[] = [];
      for (let i = 0; i < results.length; i += chunkSize) {
        const chunk = results.slice(i, i + chunkSize);
        try {
          const chunkLoadedImages = await loadChunk(chunk, i);
          loadedImages = [...loadedImages, ...chunkLoadedImages];
          setResultImages((prevImages) => [...prevImages, ...chunkLoadedImages]);
          console.log(`Loaded chunk ${i / chunkSize + 1}:`, chunkLoadedImages);
        } catch (error) {
          console.error('Error loading images:', error);
        }
      }
    };

    if (results?.length > 0) {
      console.log('Results:', results);
      loadImages();
    }
  }, [results]);

  // Update stage size on window resize
  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        setStageSize({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  // Calculate image dimensions to fit within the stage
  useEffect(() => {
    if (image && stageSize.width && stageSize.height) {
      const imgWidth = image.width;
      const imgHeight = image.height;

      // Calculate the scale to fit the image within the stage
      const scale = Math.min(stageSize.width / imgWidth, stageSize.height / imgHeight);

      // Calculate the new image dimensions
      const newWidth = imgWidth * scale;
      const newHeight = imgHeight * scale;

      // Calculate image position to center it
      const imageX = (stageSize.width - newWidth) / 2;
      const imageY = (stageSize.height - newHeight) / 2;

      setImageDimensions({ width: newWidth, height: newHeight, x: imageX, y: imageY });

      // Set scaling factors based on base image
      setScaleX(scale);
      setScaleY(scale);

      console.log(`Image scaled to: width=${newWidth}, height=${newHeight}, x=${imageX}, y=${imageY}`);
    }
  }, [image, stageSize]);

  // Handle drawing
  const [lines, setLines] = useState<any[]>([]);
  const isDrawing = useRef(false);

  const handleMouseDown = (e: any) => {
    isDrawing.current = true;
    const pos = e.target.getStage()?.getPointerPosition();
    if (pos) {
      setLines([...lines, { tool, points: [pos.x, pos.y] }]);
    }
  };

  const handleMouseMove = (e: any) => {
    if (!isDrawing.current) return;
    const stage = e.target.getStage();
    const point = stage?.getPointerPosition();
    if (!point) return;

    let lastLine = lines[lines.length - 1];
    // Add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // Replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  // Function to get color based on anomaly class
  const getColorForClass = (className: string) => {
    switch (className) {
      case 'Decay':
        return 'red';
      case 'Restoration':
        return 'green';
      case 'Missing Tooth':
        return 'yellow';
      case 'Bone Loss':
        return 'blue';
      case 'Root Canal Treatment':
        return 'purple';
      default:
        return 'white';
    }
  };

  // Function to check if the pointer position is within the bounding box
  const isPointerInsideBoundingBox = (
    boundingBox: { center: [number, number]; width: number; height: number },
    pointerPos: { x: number; y: number }
  ) => {
    const { x, y } = pointerPos;

    const boxX1 = imageDimensions.x + boundingBox.center[0] * scaleX - (boundingBox.width * scaleX) / 2;
    const boxY1 = imageDimensions.y + boundingBox.center[1] * scaleY - (boundingBox.height * scaleY) / 2;

    const boxX2 = boxX1 + boundingBox.width * scaleX;
    const boxY2 = boxY1 + boundingBox.height * scaleY;

    return x >= boxX1 && x <= boxX2 && y >= boxY1 && y <= boxY2;
  };

  const handlePointerMove = (e: any) => {
    const pointerPos = e.target.getStage()?.getPointerPosition();
    if (!pointerPos || !results || results.length === 0) {
      setHoveredSegment(null);
    } else {
      let foundSegment: number | null = null;
      for (let i = 0; i < results.length; i++) {
        const result = results[i];
        const { measurements } = result;

        if (isPointerInsideBoundingBox(measurements.bounding_box, pointerPos)) {
          foundSegment = i;
          break;
        }
      }
      setHoveredSegment(foundSegment);
    }
  };

  // Function to position the measurement info near the bounding box
  const getMeasurementPosition = (boundingBox: { center: [number, number]; width: number; height: number }) => {
    const boxX = imageDimensions.x + boundingBox.center[0] * scaleX;
    const boxY = imageDimensions.y + boundingBox.center[1] * scaleY;

    const offsetX = 10;  // Show text 10 pixels to the right of the segment
    const offsetY = 10;  // Show text 10 pixels below the top of the segment

    return {
      x: Math.min(boxX + offsetX, stageSize.width - 150), // Adjust width to accommodate text
      y: Math.min(boxY + offsetY, stageSize.height - 40), // Adjust height to accommodate text
    };
  };

  // Function to dynamically generate measurement text
  const getMeasurementText = (measurements: { [key: string]: any }) => {
    return Object.entries(measurements)
      .filter(([key]) => key !== 'bounding_box') // Exclude bounding_box from display
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  };

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      <Stage
        width={stageSize.width}
        height={stageSize.height}
        onMouseMove={handlePointerMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        style={{ background: '#000' }} // Optional: Add a background color
      >
        <Layer>
          {/* X-Ray Image */}
          {image && (
            <KonvaImage
              image={image}
              x={imageDimensions.x}
              y={imageDimensions.y}
              width={imageDimensions.width}
              height={imageDimensions.height}
            />
          )}

          {/* Result Segments with Interactive Hover Effects */}
          {results &&
            results.map((result, index) => {
              const { measurements } = result;
              const segmentImage = resultImages[index];

              if (!segmentImage) {
                console.warn(`No segment image found for result at index ${index}`);
                return null;
              }

              return (
                <Group key={`segment-${index}`}>
                  {/* Render the segment image */}
                  <KonvaImage
                    image={segmentImage}
                    x={imageDimensions.x}
                    y={imageDimensions.y}
                    width={imageDimensions.width}
                    height={imageDimensions.height}
                    opacity={hoveredSegment === index ? 0.8 : 1} // Example effect
                  />

                  {/* Display Measurement Info on Hover */}
                  {hoveredSegment === index && (
                    <Text
                      {...getMeasurementPosition(measurements.bounding_box)}
                      text={getMeasurementText(measurements)}
                      fontSize={14}
                      fill="yellow"
                      padding={5}
                      background="rgba(0,0,0,0.5)"
                    />
                  )}
                </Group>
              );
            })}

          {/* AI Analysis Overlay for Anomalies */}
          {anomalyResults?.predictions
            ?.filter((prediction) => prediction.sensitivity >= aiSettings.sensitivity)
            .map((prediction, index) => {
              const { class: className, confidence, bounding_box } = prediction;
              const color = getColorForClass(className);

              const boxX =
                imageDimensions.x +
                bounding_box.center[0] * scaleX -
                (bounding_box.width * scaleX) / 2;
              const boxY =
                imageDimensions.y +
                bounding_box.center[1] * scaleY -
                (bounding_box.height * scaleY) / 2;
              const boxWidth = bounding_box.width * scaleX;
              const boxHeight = bounding_box.height * scaleY;

              // Calculate confidence as a percentage
              const confidencePercentage = (confidence * 100).toFixed(2) + '%';

              return (
                <Group
                  key={`anomaly-${index}`}
                  onMouseEnter={() => setHoveredAnomaly(className)}
                  onMouseLeave={() => setHoveredAnomaly(null)}
                >
                  {/* Bounding Box with Glow and Rounded Corners */}
                  <Rect
                    x={boxX}
                    y={boxY}
                    width={boxWidth}
                    height={boxHeight}
                    stroke={color}
                    strokeWidth={2}
                    cornerRadius={10} // Rounded corners
                    shadowBlur={10} // Add a glow effect
                    shadowColor={color}
                    shadowOffset={{ x: 0, y: 0 }}
                  />
                  {/* Label with Class Name and Confidence */}
                  <Rect
                    x={boxX}
                    y={boxY - 23}
                    width={150} // Adjust size based on text
                    height={20}
                    fill={`${color}55`} // Semi-transparent background
                    cornerRadius={5} // Rounded background
                  />
                  <Text
                    x={boxX + 5} // Slight padding for text
                    y={boxY - 19}
                    text={`${className}`}
                    fontSize={14}
                    // fontFamily="Roboto"
                    fill="white"
                    // fontStyle="bold"
                  />
                  {/* Additional Info on Hover */}
                  {hoveredAnomaly === className && (
                    <Text
                      x={boxX}
                      y={boxY + boxHeight + 10} // Below the box
                      text={`Confidence: ${confidencePercentage}`}
                      fontSize={12}
                      fill="white"
                      background="rgba(0,0,0,0.5)"
                    />
                  )}
                </Group>
              );
            })}

          {/* Drawing Layer */}
          {lines.map((line, i) => (
            <Line
              key={`line-${i}`}
              points={line.points}
              stroke={line.tool === 'pen' ? 'red' : 'white'}
              strokeWidth={2}
              tension={0.5}
              lineCap="round"
              globalCompositeOperation={
                line.tool === 'eraser' ? 'destination-out' : 'source-over'
              }
            />
          ))}
        </Layer>
      </Stage>
    </div>
  );
}
