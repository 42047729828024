import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ArrowUpDown, ChevronDown, Plus } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { Badge } from "@/components/ui/badge"
import AddNewCase from "./modal/AddNewCase"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import { useAuth } from "@/context/AuthContext"
import { Skeleton } from "./ui/skeleton"
import DashboardCharts from "./CasesDashboardChart"

const formatDate = (date: Date) => {
  const day = String(date.getDate()).padStart(2, "0") // Ensures 2-digit day
  const month = date.toLocaleString("default", { month: "short" }) // Short month name (e.g., Dec)
  const year = date.getFullYear()
  return `${day}/${month}/${year}`
}

type Case = {
  case_id: string
  name: string
  age: number
  gender: "M" | "F"
  caseDetail: string
  createdDate: string
  status: "Active" | "Closed"
}

const SkeletonCard = () => {
  return (
    <Card className="bg-sidebar-accent shadow-sm border-slate-200">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <Skeleton className="h-4 w-[100px]" />
      </CardHeader>
      <CardContent>
        <Skeleton className="h-8 w-[60px]" />
      </CardContent>
    </Card>
  )
}

export const columns: ColumnDef<Case>[] = [
  {
    accessorKey: "case_id",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="text-slate-700 hover:text-slate-900 font-medium p-0"
        >
          CASE ID
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      return <span className="text-slate-600">CS00{row.index + 1}</span>
    },
  },
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="text-slate-700 hover:text-slate-900 font-medium p-0"
        >
          NAME
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      )
    },
  },
  {
    accessorKey: "age",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="text-slate-700 hover:text-slate-900 font-medium p-0"
        >
          AGE
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      )
    },
  },
  {
    accessorKey: "gender",
    header: () => <span className="text-slate-700 font-medium">GENDER</span>,
  },
  {
    accessorKey: "caseDetail",
    header: () => (
      <span className="text-slate-700 font-medium">CASE DETAIL</span>
    ),
    cell: ({ row }) => (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="cursor-help truncate max-w-[300px] block">
              {row.getValue("caseDetail")}
            </span>
          </TooltipTrigger>
          <TooltipContent className="max-w-[500px]">
            <p>{row.getValue("caseDetail")}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ),
  },
  {
    accessorKey: "createdDate",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="text-slate-700 hover:text-slate-900 font-medium p-0"
        >
          CREATED DATE
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <span className="text-slate-600">
        {formatDate(new Date(row.original?.createdDate))}
      </span>
    ),
  },
  {
    accessorKey: "status",
    header: () => <span className="text-slate-700 font-medium">STATUS</span>,
    cell: ({ row }) => {
      const status = row.getValue("status") as string
      return (
        <Badge variant={status === "Active" ? "default" : "outline"}>
          {status}
        </Badge>
      )
    },
  },
]

export default function CaseList() {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useAuth()

  console.log(data)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const res = await axios.get(
          `https://api.vitalviewai.com/cases/${user?.uid}`
        )
        if (res.data) {
          const filtered = res.data.filter(
            (case_: any) => case_.status !== "closed"
          )
          setData(filtered)
        }
      } catch (err) {
        console.error("error fetchind data in caselist", err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [user?.uid])

  const navitate = useNavigate()

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
  })

  return (
    <div className="p-4 space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-semibold text-slate-900">Cases</h1>
          <p className="text-sm text-slate-500">
            Manage cases and their details here
          </p>
        </div>
        <AddNewCase data={data} setData={setData} />
      </div>

      <div className="">
        {isLoading ? (
          <>
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
          </>
        ) : (
          <>

            <DashboardCharts />



          </>
        )}
      </div>

      <div className="flex items-center justify-between">
        <Input
          placeholder="Search by Name..."
          value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
          onChange={(event) =>
            table.getColumn("name")?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto text-slate-600">
              Columns <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={Math.random()}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className="rounded-md border border-slate-200">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="bg-zinc-50">
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    className="py-4 px-4 text-slate-700 font-medium"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              Array(data.length || 7)
                .fill(null)
                .map((_, index) => (
                  <TableRow key={index} className="hover:bg-zinc-100">
                    {columns.map((column) => (
                      <TableCell key={column.id} className="py-4 px-4">
                        <Skeleton className="h-4 w-[100px]" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.original?.case_id}
                  className="hover:bg-zinc-100 border-zinc-200 hover:cursor-pointer"
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      onClick={() =>
                        navitate(`/cases/${row.original?.case_id}`)
                      }
                      className="py-4 px-4 text-slate-600"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center text-slate-500"
                >
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          className="text-slate-600"
        >
          Previous
        </Button>
        {Array.from({ length: table.getPageCount() }, (_, i) => i + 1).map(
          (page) => (
            <Button
              key={page}
              variant={
                table.getState().pagination.pageIndex + 1 === page
                  ? "default"
                  : "outline"
              }
              size="sm"
              onClick={() => table.setPageIndex(page - 1)}
              className={
                table.getState().pagination.pageIndex + 1 === page
                  ? "bg-slate-900 text-white hover:bg-slate-800"
                  : "text-slate-600"
              }
            >
              {page}
            </Button>
          )
        )}
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          className="text-slate-600"
        >
          Next
        </Button>
      </div>
    </div>
  )
}
