import { useState } from "react"
import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import { Textarea } from "@/components/ui/textarea"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Label } from "@/components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Checkbox } from "@/components/ui/checkbox"
import { ClipboardList } from "lucide-react"
import { toast } from "sonner"
import { useLocation } from "react-router-dom"
import { useAuth } from "@/context/AuthContext"

type FeedbackData = {
  overallAccuracy: "correct" | "partially-correct" | "incorrect" | ""
  areasNeedingReview: {
    symptoms: boolean
    diagnosis: boolean
    treatment: boolean
    medications: boolean
    differentialDiagnosis: boolean
    summary: boolean
  }
  documentationQuality: "excellent" | "good" | "fair" | "poor" | ""
  urgency: "immediate" | "high" | "medium" | "low" | ""
  additionalComments: string
}

export default function ReportFeedback() {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const location = useLocation()
  const caseId = location.pathname.split("/")[2]
  const { user } = useAuth()

  const [feedbackData, setFeedbackData] = useState<FeedbackData>({
    overallAccuracy: "",
    areasNeedingReview: {
      symptoms: false,
      diagnosis: false,
      treatment: false,
      medications: false,
      differentialDiagnosis: false,
      summary: false,
    },
    documentationQuality: "",
    urgency: "",
    additionalComments: "",
  })

  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  const submitFeedback = async () => {
    try {
      const response = await fetch("https://api.vitalviewai.com/cases/feedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user?.uid,
          case_id: caseId,
          data: {
            notes: feedbackData.additionalComments,
            overallAccuracy: feedbackData.overallAccuracy,
            areasNeedingReview: feedbackData.areasNeedingReview,
            documentationQuality: feedbackData.documentationQuality,
            urgency: feedbackData.urgency,
          },
        }),
      })

      if (response.ok) {
        toast.success("Feedback submitted successfully!")
        setIsDialogOpen(false)
        resetFeedbackData()
      } else {
        throw new Error("Failed to submit feedback")
      }
    } catch (error) {
      toast.error("There was an error submitting your feedback.")
      console.error("Feedback submission error:", error)
    }
  }

  const handleSubmit = () => {
    const validationErrors: { [key: string]: string } = {}

    // Validate required fields
    if (!feedbackData.overallAccuracy) validationErrors.overallAccuracy = "Please select the overall accuracy."
    if (!feedbackData.documentationQuality) validationErrors.documentationQuality = "Please select the documentation quality."
    if (!feedbackData.urgency) validationErrors.urgency = "Please select the urgency level."

    setErrors(validationErrors)

    if (Object.keys(validationErrors).length === 0) {
      submitFeedback()
    } else {
      toast.error("Please fix the highlighted errors before submitting.")
    }
  }

  const updateFeedbackData = (field: keyof FeedbackData, value: any) => {
    setFeedbackData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const updateAreaReview = (area: keyof FeedbackData["areasNeedingReview"], value: boolean) => {
    setFeedbackData((prev) => ({
      ...prev,
      areasNeedingReview: {
        ...prev.areasNeedingReview,
        [area]: value,
      },
    }))
  }

  const resetFeedbackData = () => {
    setFeedbackData({
      overallAccuracy: "",
      areasNeedingReview: {
        symptoms: false,
        diagnosis: false,
        treatment: false,
        medications: false,
        differentialDiagnosis: false,
        summary: false,
      },
      documentationQuality: "",
      urgency: "",
      additionalComments: "",
    })
    setErrors({})
  }

  return (
    <div className="flex flex-col items-end justify-center p-4">
      <Button
        className="w-full sm:w-auto flex items-center justify-center cursor-pointer"
        onClick={() => setIsDialogOpen(true)}
        aria-label="Provide Feedback"
      >
        <ClipboardList className="mr-2 h-4 w-4" />
        Report Feedback
      </Button>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[600px] w-full">
          <DialogHeader>
            <DialogTitle>Medical Report Feedback Form</DialogTitle>
          </DialogHeader>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <div className="grid gap-6 py-4">
              {/* Overall Accuracy Assessment */}
              <div className="grid gap-2">
                <Label htmlFor="overallAccuracy">Overall Accuracy Assessment<span className="text-red-500">*</span></Label>
                <RadioGroup
                  value={feedbackData.overallAccuracy}
                  onValueChange={(value) => updateFeedbackData("overallAccuracy", value)}
                  aria-labelledby="overallAccuracy"
                >
                  <div className="flex items-center space-x-4">
                    <RadioGroupItem value="correct" id="correct" />
                    <Label htmlFor="correct">Correct</Label>
                  </div>
                  <div className="flex items-center space-x-4">
                    <RadioGroupItem value="partially-correct" id="partially-correct" />
                    <Label htmlFor="partially-correct">Partially Correct</Label>
                  </div>
                  <div className="flex items-center space-x-4">
                    <RadioGroupItem value="incorrect" id="incorrect" />
                    <Label htmlFor="incorrect">Incorrect</Label>
                  </div>
                </RadioGroup>
                {errors.overallAccuracy && (
                  <span className="text-red-500 text-sm">{errors.overallAccuracy}</span>
                )}
              </div>

              {/* Areas Needing Review */}
              <div className="grid gap-2">
                <Label>Areas Needing Review (Check all that apply)</Label>
                <div className="grid grid-cols-2 gap-2">
                  {[
                    { id: "symptoms", label: "Symptoms" },
                    { id: "diagnosis", label: "Diagnosis" },
                    { id: "treatment", label: "Treatment Plan" },
                    { id: "medications", label: "Medications" },
                    { id: "differentialDiagnosis", label: "Differential Diagnosis" },
                    { id: "summary", label: "Summary" },
                  ].map(({ id, label }) => (
                    <div className="flex items-center space-x-2" key={id}>
                      <Checkbox
                        id={id}
                        checked={feedbackData.areasNeedingReview[id as keyof FeedbackData["areasNeedingReview"]]}
                        // @ts-ignore
                        onCheckedChange={(checked) => updateAreaReview(id as keyof FeedbackData["areasNeedingReview"], checked)}
                        aria-label={label}
                      />
                      <Label htmlFor={id}>{label}</Label>
                    </div>
                  ))}
                </div>
              </div>

              {/* Documentation Quality */}
              <div className="grid gap-2">
                <Label htmlFor="documentationQuality">Documentation Quality<span className="text-red-500">*</span></Label>
                <Select
                  value={feedbackData.documentationQuality}
                  onValueChange={(value) => updateFeedbackData("documentationQuality", value)}
                  aria-labelledby="documentationQuality"
                >
                  <SelectTrigger id="documentationQuality">
                    <SelectValue placeholder="Select quality level" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="excellent">Excellent</SelectItem>
                    <SelectItem value="good">Good</SelectItem>
                    <SelectItem value="fair">Fair</SelectItem>
                    <SelectItem value="poor">Poor</SelectItem>
                  </SelectContent>
                </Select>
                {errors.documentationQuality && (
                  <span className="text-red-500 text-sm">{errors.documentationQuality}</span>
                )}
              </div>

              {/* Urgency of Review */}
              <div className="grid gap-2">
                <Label htmlFor="urgency">Urgency of Review<span className="text-red-500">*</span></Label>
                <Select
                  value={feedbackData.urgency}
                  onValueChange={(value) => updateFeedbackData("urgency", value)}
                  aria-labelledby="urgency"
                >
                  <SelectTrigger id="urgency">
                    <SelectValue placeholder="Select urgency level" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="immediate">Immediate</SelectItem>
                    <SelectItem value="high">High</SelectItem>
                    <SelectItem value="medium">Medium</SelectItem>
                    <SelectItem value="low">Low</SelectItem>
                  </SelectContent>
                </Select>
                {errors.urgency && (
                  <span className="text-red-500 text-sm">{errors.urgency}</span>
                )}
              </div>

              {/* Additional Comments */}
              <div className="grid gap-2">
                <Label htmlFor="additionalComments">Additional Comments</Label>
                <Textarea
                  id="additionalComments"
                  placeholder="Please provide any additional feedback or specific concerns..."
                  value={feedbackData.additionalComments}
                  onChange={(e) => updateFeedbackData("additionalComments", e.target.value)}
                  aria-label="Additional Comments"
                />
              </div>
            </div>
            <DialogFooter className="flex justify-end space-x-2">
              <Button
                type="button"
                variant="secondary"
                onClick={() => setIsDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={
                  !feedbackData.overallAccuracy ||
                  !feedbackData.documentationQuality ||
                  !feedbackData.urgency
                }
              >
                Submit Feedback
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
