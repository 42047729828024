import React from 'react'
import { LoaderPinwheel } from 'lucide-react'

interface LoadingProps {
  loading: boolean
}

const Loading: React.FC<LoadingProps> = ({ loading }) => {
  if (!loading) return null

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-80">
      <LoaderPinwheel className="animate-spin text-gray-800" size={48} />
    </div>
  )
}

export default Loading
