import React, { useCallback, useEffect, useRef, useState } from "react"
import Markdown from "react-markdown"
import { CornerDownLeft, Mic, Paperclip } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip"
import { auth, db } from "@/firebase"
import { doc, setDoc, updateDoc, getDoc } from "firebase/firestore"
import { useLocation } from "react-router-dom"
import { useWebSocketContext } from "@/WebSocketContext"
import { flushSync } from "react-dom"
import FilePreview from "@/components/FilePreview"

interface Message {
  id: string
  role: "user" | "assistant"
  content: string
  file_url?: string | File
  timestamp: number
  isStreaming?: boolean
}

export default function ChatPage({
  inputText,
  setInputText,
  messages,
  setMessages,
  setLoading,
  loading,
}) {
  const [userId] = useState(auth.currentUser?.uid || "test_user_1")
  const router = useLocation()
  const id = router.pathname.split("/")[2]
  const [caseId] = useState(id || "test_case_1")
  const [sessionId] = useState(caseId)
  const [enableImgLoader, setEnableImgLoader] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const currentMessageRef = useRef("")
  const currentMessageIdRef = useRef<string | null>(null)
  const streamQueueRef = useRef<string[]>([])
  const isProcessingRef = useRef(false)
  const [isMicActive, setIsMicActive] = useState(false)
  const [listeningStatus, setListeningStatus] = useState<string | null>(null)
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const audioChunksRef = useRef<Blob[]>([])

  const messagesContainerRef = useRef<HTMLDivElement>(null)

  const [isStreamingTemp, setIsStreamingTemp] = useState(false)
  const isScrollRef = useRef(false)

  const { sendMessage: sendWebSocketMessage, lastMessage } =
    useWebSocketContext()

  const scrollToBottom = () => {
    if (messagesEndRef.current && !isScrollRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current
    if (!messagesContainer) return

    const handleScroll = () => {
      isScrollRef.current = true
    }

    messagesContainer.addEventListener("wheel", handleScroll)
    // return () => messagesContainer.removeEventListener("wheel")
  }, [])

  const sendAudioForTranscription = async (audioBlob: Blob) => {
    const formData = new FormData()
    formData.append("file", audioBlob, "recording.webm")

    try {
      const response = await fetch("https://api.vitalviewai.com/transcribe", {
        method: "POST",
        body: formData,
      })

      if (!response.ok) {
        throw new Error("Transcription failed")
      }

      const data = await response.json()
      return data.text
    } catch (error) {
      console.error("Error during transcription:", error)
      throw error
    }
  }

  const toggleMicrophone = async () => {
    if (isMicActive) {
      // Stop recording
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop()
      }
      setIsMicActive(false)
      setListeningStatus(null)
    } else {
      try {
        // Request microphone access
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        })
        const mediaRecorder = new MediaRecorder(stream)
        mediaRecorderRef.current = mediaRecorder
        audioChunksRef.current = []

        // Handle data availability
        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data)
          }
        }

        // Handle start of recording
        mediaRecorder.onstart = () => {
          setListeningStatus("🎙️ Listening...")
        }

        // Handle stop and process audio
        mediaRecorder.onstop = async () => {
          setListeningStatus("⏳ Processing...")
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/webm",
          })
          try {
            const transcribedText = await sendAudioForTranscription(audioBlob)
            sendMessage(transcribedText)
          } catch (error) {
            console.error("Error transcribing audio:", error)
            setListeningStatus("❌ Failed. Please try again.")
          } finally {
            setTimeout(() => setListeningStatus(null), 3000)
          }
        }

        // Start recording
        mediaRecorder.start()
        setIsMicActive(true)
      } catch (error) {
        console.error("Error accessing microphone:", error)
        alert(
          "Microphone access denied or unavailable. Please check permissions."
        )
      }
    }
  }

  // Process streaming queue
  const processStreamQueue = useCallback(() => {
    if (!isProcessingRef.current && streamQueueRef.current.length > 0) {
      isProcessingRef.current = true

      requestAnimationFrame(() => {
        const chunk = streamQueueRef.current.shift()
        if (chunk) {
          currentMessageRef.current += chunk
          flushSync(() => {
            setMessages((prev) => {
              const lastMessage = prev[prev.length - 1]
              if (
                lastMessage &&
                lastMessage.id === currentMessageIdRef.current
              ) {
                return [
                  ...prev.slice(0, -1),
                  {
                    ...lastMessage,
                    content: currentMessageRef.current,
                    isStreaming: true,
                  },
                ]
              }
              return prev
            })
          })
          scrollToBottom()
        }
        isProcessingRef.current = false

        // Continue processing if there are more chunks
        if (streamQueueRef.current.length > 0) {
          processStreamQueue()
        } else {
          setIsStreamingTemp(false)
        }
      })
    }
  }, [setMessages])

  // Handle WebSocket messages
  useEffect(() => {
    if (!lastMessage?.data) {
      isScrollRef.current = false
      setLoading(false)
      return
    }

    setIsStreamingTemp(true)
    const data = lastMessage.data

    if (data.startsWith("Error:")) {
      streamQueueRef.current = []
      flushSync(() => {
        setLoading(false)
        setMessages((prev) => [
          ...prev,
          {
            id: Date.now().toString(),
            role: "assistant",
            content: "An error occurred. Please try again.",
            timestamp: Date.now(),
            isStreaming: false,
          },
        ])
      })
      currentMessageRef.current = ""
      currentMessageIdRef.current = null
      scrollToBottom()
      return
    }

    if (data.includes("[DONE]")) {
      streamQueueRef.current = []
      flushSync(() => {
        setLoading(false)
        setMessages((prev) => {
          const newMessages = [...prev]
          const lastIndex = newMessages.length - 1
          if (
            lastIndex >= 0 &&
            newMessages[lastIndex].id === currentMessageIdRef.current
          ) {
            newMessages[lastIndex] = {
              ...newMessages[lastIndex],
              isStreaming: false,
            }
          }
          return newMessages
        })
      })

      scrollToBottom()
      currentMessageRef.current = ""
      currentMessageIdRef.current = null
      return
    }

    // Add new chunks to the queue and process them
    streamQueueRef.current.push(data)
    processStreamQueue()
  }, [lastMessage, processStreamQueue, setLoading, setMessages])

  const sendMessage = async (message: string) => {
    if (!message.trim() && !selectedFile) return

    isScrollRef.current = false

    const newMessageId = Date.now().toString()
    currentMessageIdRef.current = newMessageId
    currentMessageRef.current = ""
    streamQueueRef.current = []

    flushSync(() => {
      setMessages((prev) => [
        ...prev,
        {
          id: Date.now().toString(),
          role: "user",
          content: message,
          file_url: selectedFile || "",
          timestamp: Date.now(),
          isStreaming: false,
        },
        {
          id: newMessageId,
          role: "assistant",
          content: "",
          timestamp: Date.now(),
          isStreaming: true,
        },
      ])
      setInputText("")
      setLoading(true)
    })

    scrollToBottom()

    sendWebSocketMessage({
      user_id: userId,
      session_id: sessionId,
      message: message,
      file_url: selectedFile || "",
    })

    setSelectedFile(null)
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnableImgLoader(true)
    const file = event.target.files?.[0]
    if (file) {
      const formData = new FormData()
      formData.append("user_id", userId)
      formData.append("file", file)
      try {
        const response = await fetch("https://api.vitalviewai.com/upload", {
          method: "POST",
          body: formData,
        })
        const data = await response.json()
        setSelectedFile(data.file_url)
      } catch (error) {
        console.error("Error uploading file:", error)
      }
      setEnableImgLoader(false)
    }
  }

  return (
    <div className="h-full flex z-50 flex-col md:flex-row">
      <div className={`flex-1 flex flex-col mx-auto max-w-5xl`}>
        <div
          ref={messagesContainerRef}
          className="flex-1 overflow-y-auto mb-1 p-2 rounded-lg "
        >
          <div className="flex flex-col space-y-2 m-2">
            {messages.map((msg, index) => (
              <div
                key={Math.random()}
                className={`flex p-3 rounded-lg ${
                  msg.role === "user"
                    ? "bg-message-human self-end text-left max-w-[75%]"
                    : "self-start text-left"
                } ${msg.role === "assistant" ? "bg-white" : ""}`}
              >
                {msg.role === "assistant" && (
                  <div className="mr-3">
                    <img src="/bot.svg" alt="" width="30px" height="30px" />
                  </div>
                )}
                {msg.role === "assistant" &&
                  loading &&
                  index === messages.length - 1 && (
                    <img src="/mssg-loading.svg"></img>
                  )}
                <div className="flex-1">
                  <Markdown>{msg.content}</Markdown>
                  {msg.file_url && (
                    <div className="mt-2">
                      <img
                        src={
                          typeof msg.file_url === "string" ? msg.file_url : ""
                        }
                        alt="File preview"
                        className="max-w-[200px] rounded-md"
                      />
                    </div>
                  )}
                  {msg.role === "assistant" &&
                    isStreamingTemp &&
                    index === messages.length - 1 && (
                      <div className="mt-1">
                        <span className="animate-pulse">▮</span>
                      </div>
                    )}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault()
            sendMessage(inputText)
          }}
          className="flex flex-col p-4 sticky bottom-0   bg-white"
        >
          <FilePreview fileUrl={selectedFile} />
          <div className="relative overflow-hidden rounded-sm border bg-background focus-within:ring-1 focus-within:ring-ring">
            <Label htmlFor="message" className="sr-only">
              Message
            </Label>
            <Textarea
              id="message"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault()
                  sendMessage(inputText)
                }
              }}
              placeholder="Message the assistant..."
              className="h-12 resize-none border-0 p-3 shadow-none focus-visible:ring-0"
            />
            <div className="flex items-center p-3 pt-0">
              <div className={`${!enableImgLoader ? "" : "sr-only"}`}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      onClick={() => fileInputRef.current?.click()}
                      variant="ghost"
                      size="icon"
                      type="button"
                    >
                      <Paperclip className="size-4" />
                      <span className="sr-only">Attach file</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side="top">Attach File</TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={toggleMicrophone}
                      className={isMicActive ? "text-red-500" : ""}
                    >
                      <Mic className="size-4" />
                      <span className="sr-only">Use Microphone</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side="top">
                    {listeningStatus || "Use Microphone"}
                  </TooltipContent>
                </Tooltip>
                {listeningStatus && (
                  <span className="ml-2 text-sm text-gray-500">
                    {listeningStatus}
                  </span>
                )}
              </div>

              <div
                className={`inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] ${
                  enableImgLoader ? "" : "sr-only"
                }`}
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>

              <Button type="submit" size="sm" className="ml-auto gap-1.5">
                Send Message
                <CornerDownLeft className="size-3.5" />
              </Button>
            </div>
          </div>
        </form>

        <input
          ref={fileInputRef}
          type="file"
          accept="image/*,application/pdf,.doc,.docx,.txt"
          className="hidden"
          onChange={handleFileChange}
        />
      </div>
    </div>
  )
}
