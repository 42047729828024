import { useState } from 'react'
import { Bar, Line, Pie } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  ArcElement
} from 'chart.js'
import { Button } from "@/components/ui/button"
import { Progress } from "@/components/ui/progress"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Pagination, PaginationContent, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from "@/components/ui/pagination"
import { Input } from "@/components/ui/input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import {
  AlertTriangle,
  ArrowUpRight,
  CheckCircle2,
  Download,
  Lock,
  Printer,
  RefreshCcw,
  XCircle,
  FileText,
  Users,
  Zap
} from 'lucide-react'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  ArcElement
)

export default function ClinicalStudy() {
  const [activeTab, setActiveTab] = useState('overview')
  const [participantSearch, setParticipantSearch] = useState('')
  const [participantSort, setParticipantSort] = useState('id')
  const [page, setPage] = useState(1)
  const itemsPerPage = 5

  // Mock data (in a real application, this would come from an API or database)
  const studyProgress = {
    patientsProcessed: 450,
    totalPatients: 600,
    targetCompletionDate: '2024-12-31',
    milestones: [
      { name: 'Study Initiation', completed: true },
      { name: 'Mid-point Review', completed: false },
      { name: 'Final Analysis', completed: false },
    ],
    startDate: '2023-01-01',
    overallAIAccuracy: 0.91,
    timeSaved: 15,
    patientSatisfaction: 0.95,
  }

  const participantSummary = [
    { id: 1, age: 45, gender: 'F', condition: 'Periodontal Disease', aiAccuracy: 0.92 },
    { id: 2, age: 32, gender: 'M', condition: 'Dental Caries', aiAccuracy: 0.88 },
    { id: 3, age: 58, gender: 'F', condition: 'Root Canal', aiAccuracy: 0.95 },
    { id: 4, age: 27, gender: 'F', condition: 'Wisdom Tooth Extraction', aiAccuracy: 0.91 },
    { id: 5, age: 62, gender: 'M', condition: 'Dental Implant', aiAccuracy: 0.89 },
  ]

  const diagnosticAccuracy = {
    correct: 410,
    incorrect: 40,
    truePositives: 380,
    falsePositives: 30,
    trueNegatives: 20,
    falseNegatives: 20
  }

  const confidenceLevels = {
    labels: ['Very Low', 'Low', 'Medium', 'High', 'Very High'],
    datasets: [
      {
        label: 'Correct Diagnoses',
        data: [10, 30, 100, 200, 70],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Incorrect Diagnoses',
        data: [15, 10, 8, 5, 2],
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      }
    ]
  }

  const errorClassification = [
    { type: 'Missed Caries', count: 15 },
    { type: 'Incorrect Periodontal Diagnosis', count: 12 },
    { type: 'Misclassified Root Canal', count: 8 },
    { type: 'Overlooked Fracture', count: 5 },
  ]

  const humanBenchmark = {
    labels: ['Caries Detection', 'Periodontal Diagnosis', 'Root Canal Assessment', 'Fracture Detection'],
    datasets: [
      {
        label: 'AI Accuracy',
        data: [0.92, 0.88, 0.95, 0.85],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Human Clinician Accuracy',
        data: [0.95, 0.92, 0.97, 0.90],
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      }
    ]
  }

  const primaryOutcomes = {
    diagnosticAccuracy: 0.91,
    timeEfficiency: 0.85,
    followUpRate: 0.78
  }

  const secondaryOutcomes = {
    patientSatisfaction: 0.89,
    casesRequiringReview: 0.15
  }

  const outcomeTrends = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
    datasets: [
      {
        label: 'AI Performance',
        data: [0.85, 0.87, 0.89, 0.90, 0.91],
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  }

  const consentData = {
    labels: ['Consented', 'Declined', 'Pending'],
    datasets: [
      {
        data: [450, 30, 120],
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(255, 99, 132, 0.6)',
          'rgba(255, 206, 86, 0.6)',
        ],
      },
    ],
  }

  const anonymizedData = [
    { id: 'A001', age: '30-40', gender: 'F', diagnosis: 'Periodontal Disease', aiAccuracy: 0.93 },
    { id: 'A002', age: '50-60', gender: 'M', diagnosis: 'Dental Caries', aiAccuracy: 0.89 },
    { id: 'A003', age: '20-30', gender: 'F', diagnosis: 'Root Canal', aiAccuracy: 0.95 },
    { id: 'A004', age: '40-50', gender: 'M', diagnosis: 'Dental Implant', aiAccuracy: 0.91 },
    { id: 'A005', age: '60-70', gender: 'F', diagnosis: 'Wisdom Tooth Extraction', aiAccuracy: 0.88 },
  ]

  const filteredParticipants = participantSummary.filter(p =>
    p.condition.toLowerCase().includes(participantSearch.toLowerCase()) ||
    p.gender.toLowerCase().includes(participantSearch.toLowerCase())
  ).sort((a, b) => {
    if (participantSort === 'age') return a.age - b.age
    if (participantSort === 'aiAccuracy') return b.aiAccuracy - a.aiAccuracy
    return a.id - b.id
  })

  const totalPages = Math.ceil(filteredParticipants.length / itemsPerPage)
  const paginatedParticipants = filteredParticipants.slice((page - 1) * itemsPerPage, page * itemsPerPage)

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Clinical Studies</h1>

      <Tabs value={activeTab} onValueChange={setActiveTab} className="space-y-4">
        <TabsList>
          <TabsTrigger value="overview">Overview</TabsTrigger>
          <TabsTrigger value="ai-performance">AI Performance</TabsTrigger>
          <TabsTrigger value="validation">Validation</TabsTrigger>
          <TabsTrigger value="outcomes">Outcomes</TabsTrigger>
          <TabsTrigger value="ethics">Ethics & Privacy</TabsTrigger>
          <TabsTrigger value="feedback">Feedback & Learning</TabsTrigger>
          <TabsTrigger value="data-management">Data Management</TabsTrigger>
        </TabsList>

        <TabsContent value="overview" className="space-y-4">
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            <Card className="col-span-2">
              <CardHeader>
                <CardTitle>Study Progress Tracker</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div className="flex justify-between">
                    <span>Patients Processed:</span>
                    <span className="font-semibold">{studyProgress.patientsProcessed} / {studyProgress.totalPatients}</span>
                  </div>
                  <Progress value={(studyProgress.patientsProcessed / studyProgress.totalPatients) * 100} />
                  <div className="flex justify-between text-sm text-muted-foreground">
                    <span>Start: {new Date(studyProgress.startDate).toLocaleDateString()}</span>
                    <span>Target: {new Date(studyProgress.targetCompletionDate).toLocaleDateString()}</span>
                  </div>
                  <div className="space-y-2">
                    <h4 className="font-semibold">Milestones:</h4>
                    <ul className="space-y-1">
                      {studyProgress.milestones.map((milestone, index) => (
                        <li key={index} className="flex items-center">
                          {milestone.completed ? (
                            <CheckCircle2 className="text-green-500 mr-2 h-4 w-4" />
                          ) : (
                            <AlertTriangle className="text-yellow-500 mr-2 h-4 w-4" />
                          )}
                          <span className={milestone.completed ? "line-through" : ""}>{milestone.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Key Performance Indicators</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div className="flex flex-col">
                    <span className="text-2xl font-bold">{(studyProgress.overallAIAccuracy * 100).toFixed(1)}%</span>
                    <span className="text-sm text-muted-foreground">Overall AI Accuracy</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-2xl font-bold">{studyProgress.timeSaved} min</span>
                    <span className="text-sm text-muted-foreground">Avg. Time Saved per Diagnosis</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-2xl font-bold">{(studyProgress.patientSatisfaction * 100).toFixed(1)}%</span>
                    <span className="text-sm text-muted-foreground">Patient Satisfaction Rate</span>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>

          <Card>
            <CardHeader>
              <CardTitle>Participant Summary</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex items-center gap-4">
                  <Input
                    placeholder="Search participants..."
                    className="max-w-sm"
                    value={participantSearch}
                    onChange={(e) => setParticipantSearch(e.target.value)}
                  />
                  <Select value={participantSort} onValueChange={setParticipantSort}>
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Sort by" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="id">ID</SelectItem>
                      <SelectItem value="age">Age</SelectItem>
                      <SelectItem value="aiAccuracy">AI Accuracy</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>ID</TableHead>
                      <TableHead>Age</TableHead>
                      <TableHead>Gender</TableHead>
                      <TableHead>Condition</TableHead>
                      <TableHead>AI Accuracy</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {paginatedParticipants.map((participant) => (
                      <TableRow key={participant.id}>
                        <TableCell>{participant.id}</TableCell>
                        <TableCell>{participant.age}</TableCell>
                        <TableCell>{participant.gender}</TableCell>
                        <TableCell>{participant.condition}</TableCell>
                        <TableCell>{(participant.aiAccuracy * 100).toFixed(1)}%</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Pagination>
                  <PaginationContent>
                    <PaginationItem>
                      <PaginationPrevious onClick={() => setPage(page > 1 ? page - 1 : 1)} />
                    </PaginationItem>
                    {[...Array(totalPages)].map((_, i) => (
                      <PaginationItem key={i}>
                        <PaginationLink onClick={() => setPage(i + 1)} isActive={page === i + 1}>
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem>
                      <PaginationNext onClick={() => setPage(page < totalPages ? page + 1 : totalPages)} />
                    </PaginationItem>
                  </PaginationContent>
                </Pagination>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="ai-performance" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Diagnostic Accuracy Scorecard</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h4 className="font-semibold mb-2">Overall Accuracy</h4>
                  <div className="text-3xl font-bold">
                    {((diagnosticAccuracy.correct / (diagnosticAccuracy.correct + diagnosticAccuracy.incorrect)) * 100).toFixed(1)}%
                  </div>
                </div>
                <div>
                  <h4 className="font-semibold mb-2">Metrics</h4>
                  <ul className="space-y-1">
                    <li>True Positives: {diagnosticAccuracy.truePositives}</li>
                    <li>False Positives: {diagnosticAccuracy.falsePositives}</li>
                    <li>True Negatives: {diagnosticAccuracy.trueNegatives}</li>
                    <li>False Negatives: {diagnosticAccuracy.falseNegatives}</li>
                  </ul>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Confidence Level Tracking</CardTitle>
            </CardHeader>
            <CardContent>
              <Bar data={confidenceLevels} options={{ responsive: true, maintainAspectRatio: false }} />
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Error Classification</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Error Type</TableHead>
                    <TableHead>Count</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {errorClassification.map((error, index) => (
                    <TableRow key={index}>
                      <TableCell>{error.type}</TableCell>
                      <TableCell>{error.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="validation" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Human Clinician Benchmarking</CardTitle>
            </CardHeader>
            <CardContent>
              <Bar
                data={humanBenchmark}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      beginAtZero: true,
                      max: 1,
                      ticks: {
                        format: {
                          style: 'percent'
                        }
                      }
                    }
                  }
                }}
              />
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Model Improvement Suggestions</CardTitle>
            </CardHeader>
            <CardContent>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <ArrowUpRight className="mr-2 text-blue-500" />
                  Retrain on missed caries cases to improve detection accuracy
                </li>
                <li className="flex items-center">
                  <ArrowUpRight className="mr-2 text-blue-500" />
                  Enhance periodontal disease classification with more diverse training data
                </li>
                <li className="flex items-center">
                  <ArrowUpRight className="mr-2 text-blue-500" />
                  Improve fracture detection algorithms based on recent error patterns
                </li>
                <li className="flex items-center">
                  <ArrowUpRight className="mr-2 text-blue-500" />
                  Fine-tune root canal assessment to match human clinician accuracy
                </li>
              </ul>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Validation Metrics</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Metric</TableHead>
                    <TableHead>AI Performance</TableHead>
                    <TableHead>Human Clinician</TableHead>
                    <TableHead>Difference</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>Sensitivity</TableCell>
                    <TableCell>0.92</TableCell>
                    <TableCell>0.95</TableCell>
                    <TableCell>-0.03</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Specificity</TableCell>
                    <TableCell>0.88</TableCell>
                    <TableCell>0.90</TableCell>
                    <TableCell>-0.02</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Accuracy</TableCell>
                    <TableCell>0.91</TableCell>
                    <TableCell>0.93</TableCell>
                    <TableCell>-0.02</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>F1 Score</TableCell>
                    <TableCell>0.90</TableCell>
                    <TableCell>0.92</TableCell>
                    <TableCell>-0.02</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="outcomes" className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Card>
              <CardHeader>
                <CardTitle>Primary Outcome Metrics</CardTitle>
              </CardHeader>
              <CardContent>
                <ul className="space-y-2">
                  <li className="flex justify-between">
                    <span>Diagnostic Accuracy:</span>
                    <span className="font-semibold">{(primaryOutcomes.diagnosticAccuracy * 100).toFixed(1)}%</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Time Efficiency:</span>
                    <span className="font-semibold">{(primaryOutcomes.timeEfficiency * 100).toFixed(1)}%</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Successful Follow-up Rate:</span>
                    <span className="font-semibold">{(primaryOutcomes.followUpRate * 100).toFixed(1)}%</span>
                  </li>
                </ul>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Secondary Outcome Metrics</CardTitle>
              </CardHeader>
              <CardContent>
                <ul className="space-y-2">
                  <li className="flex justify-between">
                    <span>Patient Satisfaction:</span>
                    <span className="font-semibold">{(secondaryOutcomes.patientSatisfaction * 100).toFixed(1)}%</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Cases Requiring Review:</span>
                    <span className="font-semibold">{(secondaryOutcomes.casesRequiringReview * 100).toFixed(1)}%</span>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </div>

          <Card>
            <CardHeader>
              <CardTitle>Real-Time Outcome Analysis</CardTitle>
            </CardHeader>
            <CardContent>
              <Line data={outcomeTrends} options={{ responsive: true, maintainAspectRatio: false }} />
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="ethics" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Consent Management</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <Pie data={consentData} options={{ responsive: true, maintainAspectRatio: false }} />
                </div>
                <div>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Patient ID</TableHead>
                        <TableHead>Consent Status</TableHead>
                        <TableHead>Date</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      <TableRow>
                        <TableCell>P001</TableCell>
                        <TableCell><CheckCircle2 className="text-green-500" /></TableCell>
                        <TableCell>2023-06-15</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>P002</TableCell>
                        <TableCell><CheckCircle2 className="text-green-500" /></TableCell>
                        <TableCell>2023-06-16</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>P003</TableCell>
                        <TableCell><XCircle className="text-red-500" /></TableCell>
                        <TableCell>2023-06-17</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Anonymized Data Dashboard</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Anonymized ID</TableHead>
                    <TableHead>Age Range</TableHead>
                    <TableHead>Gender</TableHead>
                    <TableHead>Diagnosis</TableHead>
                    <TableHead>AI Accuracy</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {anonymizedData.map((data) => (
                    <TableRow key={data.id}>
                      <TableCell>{data.id}</TableCell>
                      <TableCell>{data.age}</TableCell>
                      <TableCell>{data.gender}</TableCell>
                      <TableCell>{data.diagnosis}</TableCell>
                      <TableCell>{(data.aiAccuracy * 100).toFixed(1)}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Automated Compliance Reporting</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <span>Data Privacy Compliance:</span>
                  <span className="font-semibold text-green-500">98% Adherence</span>
                </div>
                <Progress value={98} className="h-2" />
                <div className="flex items-center justify-between">
                  <span>Ethical Guidelines Adherence:</span>
                  <span className="font-semibold text-green-500">100% Compliance</span>
                </div>
                <Progress value={100} className="h-2" />
                <div className="flex space-x-4 mt-4">
                  <Button className="flex-1">
                    <Printer className="mr-2 h-4 w-4" />
                    Generate Compliance Report
                  </Button>
                  <Button variant="outline" className="flex-1">
                    <Lock className="mr-2 h-4 w-4" />
                    View Access Logs
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="feedback" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Performance Insights</CardTitle>
            </CardHeader>
            <CardContent>
              <ul className="space-y-2">
                <li>AI accuracy improved by 5% after incorporating clinician feedback on periodontal cases</li>
                <li>Reduction in false positives for caries detection following model adjustments</li>
                <li>Increased confidence in fracture diagnoses after additional training</li>
              </ul>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Continuous Learning Feed</CardTitle>
            </CardHeader>
            <CardContent>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <RefreshCcw className="mr-2 text-green-500" />
                  Radiographic interpretation module updated (Version 2.3)
                </li>
                <li className="flex items-center">
                  <RefreshCcw className="mr-2 text-green-500" />
                  Periodontal disease classification algorithm refined (Version 1.7)
                </li>
              </ul>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Feedback Loop Metrics</CardTitle>
            </CardHeader>
            <CardContent>
              <p>AI model adjustments based on human feedback:</p>
              <Progress value={75} className="mt-2" />
              <p className="mt-2">75% of suggestions implemented, resulting in 8% overall accuracy improvement</p>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="data-management" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Secure Data Storage and Retrieval</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <p>All study data is encrypted and stored securely. Access is restricted to authorized personnel only.</p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="flex flex-col items-center p-4 bg-muted rounded-lg">
                    <FileText className="h-8 w-8 mb-2" />
                    <span className="text-lg font-semibold">10,000</span>
                    <span className="text-sm text-muted-foreground">Documents Stored</span>
                  </div>
                  <div className="flex flex-col items-center p-4 bg-muted rounded-lg">
                    <Users className="h-8 w-8 mb-2" />
                    <span className="text-lg font-semibold">25</span>
                    <span className="text-sm text-muted-foreground">Authorized Users</span>
                  </div>
                  <div className="flex flex-col items-center p-4 bg-muted rounded-lg">
                    <Zap className="h-8 w-8 mb-2" />
                    <span className="text-lg font-semibold">99.99%</span>
                    <span className="text-sm text-muted-foreground">Uptime</span>
                  </div>
                </div>
                <Button className="w-full">
                  <Lock className="mr-2 h-4 w-4" />
                  Access Secure Data Portal
                </Button>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Data Export Options</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <p>Export anonymized data for further analysis or publication purposes.</p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <Button className="flex-1">
                    <Download className="mr-2 h-4 w-4" />
                    Export Anonymized Data (CSV)
                  </Button>
                  <Button variant="outline" className="flex-1">
                    <Download className="mr-2 h-4 w-4" />
                    Export Anonymized Data (JSON)
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Automated Report Generation</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <p>Generate comprehensive study reports detailing AI performance, error analysis, and recommendations for model improvements.</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <Button>
                    <Printer className="mr-2 h-4 w-4" />
                    Generate Full Study Report
                  </Button>
                  <Button variant="outline">
                    <Printer className="mr-2 h-4 w-4" />
                    Generate Executive Summary
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  )
}