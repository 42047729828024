import React, { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog'
import { Slider } from '@/components/ui/slider'
import { Switch } from '@/components/ui/switch'
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '@/components/ui/tooltip'
import {
  ZoomInIcon,
  ZoomOutIcon,
  TrashIcon,
  PenToolIcon,
  EraserIcon,
  ChevronDownIcon,
} from 'lucide-react'
import { KonvaCanvas } from './KonvaCanvas'
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from '@/components/ui/select'
import axios from 'axios'
import { UploadFile } from '@/components/UploadFile'
import MeasureSettings from '@/components/MeasureSettings'
import { uploadToStorage } from '@/utils'
import { detectAnomalies } from '@/services/inference'
import { useAuth } from '@/context/AuthContext'

export function Measure() {
  const { user } = useAuth()
  const [files, setFiles] = useState<File[]>([])
  const [fileUrl, setFileUrl] = useState<string | null>("")
  const [aiSettingsOpen, setAISettingsOpen] = useState<boolean>(false)
  const [tool, setTool] = useState<'pen' | 'eraser'>('pen')
  const [anomalyResults, setAnomalyResults] = useState<any>(null)
  const [results, setResults] = useState<any[]>([]) // Initialize as an empty array
  const [loading, setLoading] = useState(false)
  // AI settings state
  const [aiSettings, setAISettings] = useState({
    cariesDetection: true,
    boneDensityAnalysis: false,
    rootCanalIdentification: false,
    sensitivity: 0.5,
    lengthMeasurement: true,
    curvatureAnalysis: false,
    angleMeasurement: false,
    densityMeasurement: false,
    enableMeasurement: false,
  })

  const processImage = async () => {
    setLoading(true)
    try {
      const body = {
        image_url: fileUrl,
        case_id: 'default',
        user_id: user?.uid,
      }
      const res = await axios.post('https://api.vitalviewai.com/segment', body)
      setResults(res.data.segmentations)
    } catch (error) {
      console.error('Error processing image:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files
    if (selectedFiles) {
      setFiles(Array.from(selectedFiles))
      setFileUrl(await uploadToStorage(selectedFiles[0], 'user123'))
    }
  }

  // Handle reset
  const handleReset = () => {
    setFiles([])
  }

  return (
    <div className="flex flex-col h-full">
      {/* Header Toolbar */}
      {files.length > 0 && (
        <header className="flex items-center justify-between p-2 border-b bg-white">
          <div className="flex items-center space-x-2">
            {/* Drawing Tools */}
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={tool === 'pen' ? 'default' : 'ghost'}
                  size="icon"
                  onClick={() => setTool('pen')}
                >
                  <PenToolIcon className="w-4 h-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Pen Tool</TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={tool === 'eraser' ? 'default' : 'ghost'}
                  size="icon"
                  onClick={() => setTool('eraser')}
                >
                  <EraserIcon className="w-4 h-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Eraser Tool</TooltipContent>
            </Tooltip>

            {/* Zoom Controls */}
            <Button variant="ghost" size="icon">
              <ZoomOutIcon className="w-4 h-4" />
            </Button>
            <Button variant="ghost" size="icon">
              <ZoomInIcon className="w-4 h-4" />
            </Button>

            {/* Reset Button */}
            <Button variant="ghost" size="icon" onClick={handleReset}>
              <TrashIcon className="w-4 h-4" />
            </Button>

            {/* AI Settings Toggle for Mobile */}
            <div className="lg:hidden">
              <Button variant="outline" size="sm" onClick={() => setAISettingsOpen(true)}>
                AI Settings
              </Button>
            </div>
          </div>
        </header>
      )}

      {/* Main Content Area */}
      <div className="flex flex-1 overflow-hidden">
        {/* Main Content Area (80%) */}
        <div className="w-4/5 p-4 overflow-hidden bg-zinc-100 rounded-lg">
          {files.length > 0 ? (
            <div className="h-full">
              {files.map((file, index) => (
                <div key={index} className="w-full h-full">
                  <KonvaCanvas
                    file={file}
                    //@ts-ignore
                    fileUrl={fileUrl}
                    anomalyResults={anomalyResults}
                    results={results}
                    aiSettings={aiSettings}
                    tool={tool}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col h-full">
              <UploadFile handleFileChange={handleFileChange} />
            </div>
          )}
        </div>

        {/* AI Analysis Settings Sidebar */}
        <MeasureSettings
          aiSettings={aiSettings}
          setAISettings={setAISettings}
          processImage={processImage}
          loading={loading}
          detect={async () => {
            setLoading(true)
            try {
              //@ts-ignore
              const results = await detectAnomalies(fileUrl, user?.uid, 'default') //123 is case
              setAnomalyResults(results)
            } catch (error) {
              console.error('Error detecting anomalies:', error)
            } finally {
              setLoading(false)
            }
          }}
        />
      </div>

      {/* AI Settings Dialog for Mobile */}
      <Dialog open={aiSettingsOpen} onOpenChange={setAISettingsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>AI Analysis Settings</DialogTitle>
            <DialogDescription>
              Adjust the AI analysis settings for X-ray interpretation.
            </DialogDescription>
          </DialogHeader>
          {/* AI Settings Form */}
          <div className="space-y-6">
            {/* Detection Settings Subcategory */}
            <div>
              <h3 className="text-md font-semibold mb-2">Detection Settings</h3>
              <div className="space-y-4">
                {/* Caries Detection */}
                <div className="flex items-center justify-between">
                  <Label htmlFor="caries-detection-mobile">Caries Detection</Label>
                  <Switch
                    id="caries-detection-mobile"
                    checked={aiSettings.cariesDetection}
                    onCheckedChange={(value) =>
                      setAISettings({ ...aiSettings, cariesDetection: value })
                    }
                  />
                </div>

                {/* Bone Density Analysis */}
                <div className="flex items-center justify-between">
                  <Label htmlFor="bone-density-mobile">Bone Density Analysis</Label>
                  <Switch
                    id="bone-density-mobile"
                    checked={aiSettings.boneDensityAnalysis}
                    onCheckedChange={(value) =>
                      setAISettings({ ...aiSettings, boneDensityAnalysis: value })
                    }
                  />
                </div>

                {/* Root Canal Identification */}
                <div className="flex items-center justify-between">
                  <Label htmlFor="root-canal-mobile">Root Canal Identification</Label>
                  <Switch
                    id="root-canal-mobile"
                    checked={aiSettings.rootCanalIdentification}
                    onCheckedChange={(value) =>
                      setAISettings({ ...aiSettings, rootCanalIdentification: value })
                    }
                  />
                </div>

                {/* Sensitivity Slider */}
                <div className="space-y-2">
                  <Label htmlFor="sensitivity-mobile">Sensitivity</Label>
                  <Slider
                    id="sensitivity-mobile"
                    defaultValue={[aiSettings.sensitivity]}
                    min={0}
                    max={1}
                    step={0.01}
                    onValueChange={(value) =>
                      setAISettings({ ...aiSettings, sensitivity: value[0] })
                    }
                  />
                </div>
              </div>
            </div>

            {/* Measurement Settings Subcategory */}
            <div>
              <h3 className="text-md font-semibold mb-2">Measurement Settings</h3>
              <div className="space-y-4">
                {/* Enable Measurement */}
                <div className="flex items-center justify-between">
                  <Label htmlFor="enable-measurement-mobile">Enable Measurement</Label>
                  <Switch
                    id="enable-measurement-mobile"
                    checked={aiSettings.enableMeasurement}
                    onCheckedChange={(value) =>
                      setAISettings({ ...aiSettings, enableMeasurement: value })
                    }
                  />
                </div>

                {/* Choose Model */}
                {aiSettings.enableMeasurement && (
                  <div className="space-y-2">
                    <Label htmlFor="choose-model-mobile">Choose Model</Label>
                    <Select
                      //@ts-ignore
                      value={aiSettings.selectedModel}
                      onValueChange={(value) =>
                        //@ts-ignore
                        setAISettings({ ...aiSettings, selectedModel: value })
                      }
                    >
                      <SelectTrigger id="choose-model-mobile" className="w-full">
                        <SelectValue placeholder="Select a model" />
                        <ChevronDownIcon className="w-4 h-4 ml-auto opacity-50" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="genesis">Genesis </SelectItem>
                        <SelectItem value="quantum">Quantum</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
