import { useState, useEffect } from 'react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { auth } from '@/firebase' // Adjust this path as necessary for your Firebase setup
import { sendSignInLinkToEmail, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { Link, useNavigate } from 'react-router-dom'

export default function Login() {
  const [email, setEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const navigate = useNavigate()


  // Send email link for passwordless sign-in
  const handleEmailSignIn = async (e: React.FormEvent) => {
    e.preventDefault()

    const actionCodeSettings = {
      url: 'https://app.vitalview.ai/finishSignUp', // Replace with your app's URL where the link will redirect
      handleCodeInApp: true,
    }

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings)
      window.localStorage.setItem('emailForSignIn', email)
      setIsSubmitted(true)
      console.log('Email link sent')
    } catch (error) {
      console.error('Error sending email link:', error)
    }
  }

  // Google sign-in with redirect
  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider())
      if (result.user) {
        console.log('User signed in:', result.user)
        navigate('/') // Navigate to the desired page on successful sign-in
      }
    } catch (error) {
      console.error('Error signing in with Google:', error)
    }
  }


  return (
    <div className="grid min-h-screen lg:grid-cols-2">
      {/* Left column */}
      <div className="hidden bg-black p-8 lg:flex lg:flex-col lg:justify-between">
        <div className="flex items-center">
          <img src="/logo.png" alt="VitalView AI" className="h-8 w-8 bg-white rounded-full" />
          <span className="ml-2 text-xl font-semibold text-white">VitalView AI</span>
        </div>
        <div className="space-y-4">
          <p className="text-lg text-white">
            "VitalView AI has enhanced our practice with rapid, precise diagnostics, transforming patient care with insights we can trust."
          </p>
          <p className="text-sm text-gray-400">Dr. Muhammed Sadiq, Dental Specialist </p>
        </div>
      </div>

      {/* Right column */}
      <div className="flex flex-col justify-between p-8">
        <div className="flex justify-between lg:justify-end">
          <div className="flex items-center lg:hidden">
            <img src="/logo.png" alt="VitalView AI" className="h-8 w-8" />
            <span className="ml-2 text-xl font-semibold">VitalView AI</span>
          </div>
        </div>

        <div className="mx-auto mt-6 w-full max-w-sm space-y-6">
          {isSubmitted ? (
            <div className="space-y-4 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">Check your email</h1>
              <p className="text-sm text-muted-foreground">
                We&apos;ve sent you a magic link to continue signing in
              </p>
            </div>
          ) : (
            <>
              <div className="space-y-2 text-center">
                <h1 className="text-2xl font-semibold tracking-tight">Create an account</h1>
                <p className="text-sm text-muted-foreground">
                  Enter your email below to create your account
                </p>
              </div>
              <form onSubmit={handleEmailSignIn} className="space-y-4">
                <div className="space-y-2">
                  <Input
                    type="email"
                    placeholder="name@example.com"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                </div>
                <Button type="submit" className="w-full">
                  Sign in with Email
                </Button>
              </form>
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t" />
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                  <span className="bg-background px-2 text-muted-foreground">Or continue with</span>
                </div>
              </div>
              <Button variant="outline" className="w-full" onClick={handleGoogleSignIn}>
                <svg
                  className="mr-2 h-4 w-4"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                    fill="#4285F4"
                  />
                  <path
                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                    fill="#34A853"
                  />
                  <path
                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                    fill="#EA4335"
                  />
                  <path d="M1 1h22v22H1z" fill="none" />
                </svg>
                Continue with Google
              </Button>
            </>
          )}
        </div>

        <div className="mt-6 text-center text-sm text-muted-foreground">
          By clicking continue, you agree to our{' '}
          <Link to="/terms" className="underline underline-offset-4 hover:text-primary">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link to="/privacy" className="underline underline-offset-4 hover:text-primary">
            Privacy Policy
          </Link>
          .
        </div>
      </div>
    </div>
  )
}
