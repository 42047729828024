import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "./ui/button"
import { ImagePlusIcon } from "lucide-react"

export function UploadFile({ handleFileChange }) {
  return (
    <div className="flex items-center justify-center h-full">
      <Card className="max-w-md w-full mx-auto shadow-none border-2 border-dashed border-zinc-200">
        <CardHeader className="flex flex-col items-center">
          <CardTitle className="text-center mt-4 text-xl">
            Ready to Upload Your X-ray?
          </CardTitle>
          <p className="text-gray-500 text-center font-thin text-md mt-2">
            Upload your X-ray image here, and let VitalView AI handle the rest with its powerful diagnostic tools!


          </p>
        </CardHeader>
        <CardContent className="flex flex-col items-center">
          <Button variant="default" size="lg" asChild>
            <label htmlFor="file-upload" className="flex items-center cursor-pointer">
              <ImagePlusIcon className="w-6 h-6 mr-2" />
              Upload X-Rays
            </label>
          </Button>
          <input
            id="file-upload"
            type="file"
            accept="image/*, .dcm"
            onChange={handleFileChange}
            multiple
            className="hidden"
          />
        </CardContent>
      </Card>
    </div>
  )
}