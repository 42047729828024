import React, { useEffect, useState } from "react"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Badge } from "@/components/ui/badge"
import { Progress } from "@/components/ui/progress"
import {
  MoreHorizontal,
  Download,
  Calendar,
  Clock,
  AlertCircle,
  CheckCircle2,
  Pill,
  Activity,
  Calendar as CalendarIcon,
  AlertTriangle,
  Clock as TimeIcon,
  ClipboardCheck,
  Heart,
  Stethoscope,
} from "lucide-react"
import MarkdownPreview from "@uiw/react-markdown-preview"

const tabsBlacklist = [
  "AIExplanation",
  "AnomalyDetection",
  "HumanReview",
  "ImagingFindings",
  "MedicalHistory",
  "SymptomTokens",
  "VisualAnalytics",
  "FinalDiagnosis",
  "GuidelinesCompliance",
  "DifferentialDiagnosis",
  "OptimizedTreatmentPlan",

  "PossibleConditions",
  "ExtractedSymptoms",
  "MedicationAnalysis",
]

const normalizeMedicationData = (data) => {
  if (Array.isArray(data)) {
    // Data is already in the correct array format
    return data.map((medication) => ({
      ...medication,
      interactions: normalizeInteractions(medication.interactions),
    }))
  } else if (typeof data === "object" && data !== null) {
    // Convert object structure to array, adding `name` from keys if not present
    return Object.entries(data).map(([key, value]) => ({
      //@ts-ignore
      ...value,
      name: key, // Use the key as `name`
      //@ts-ignore
      interactions: normalizeInteractions(value.interactions),
    }))
  }
  // Return empty array if data is not in a valid format
  return []
}

// Normalize the `interactions` field directly within the main function
const normalizeInteractions = (interactions) => {
  if (!interactions) return []
  if (Array.isArray(interactions)) {
    if (typeof interactions[0] === "object" && interactions[0] !== null) {
      // Convert interaction objects to strings
      return interactions.map(
        (interaction) =>
          `${interaction.condition}: ${interaction.description}`
      )
    }
    // Already a string array, return as is
    return interactions
  }
  // If interactions is not an array, return empty array
  return []
}

export default function CaseWidget({ summary, realtimeSummary }) {
  const [diagnosis, setDiagnosis] = useState(
    summary.structured_report?.diagnosis
  )
  const [detailedFindings, setDetailedFindings] = useState(
    summary.structured_report?.detailedFindings
  )
  const [differentialDiagnosis, setDifferentialDiagnosis] = useState(
    summary.structured_report?.differentialDiagnosis
  )
  const [medicationAnalysis, setMedicationAnalysis]: any = useState([])



  const [optimizedTreatmentPlan, setOptimizedTreatmentPlan] = useState(
    summary.structured_report?.optimizedTreatmentPlan
  )
  const [treatmentPlan, setTreatmentPlan] = useState(
    summary.structured_report?.treatmentPlan
  )

  const isDetailedFindingArray = Array.isArray(
    detailedFindings?.keyCharacteristics
  )

  const isTreateMentPlanArray = Array.isArray(treatmentPlan?.immediateActions)


  useEffect(() => {
    // Normalize the data when summary changes
    try {
      const normalizedData = normalizeMedicationData(
        summary.structured_report?.medicationAnalysis?.medications || summary.structured_report?.medicationAnalysis || []
      )
      setMedicationAnalysis(normalizedData)
    } catch (error) {
      console.error("Error normalizing medication data:", error)
      setMedicationAnalysis([])
    }
  }, [summary])

  useEffect(() => {
    if (realtimeSummary) {
      setDiagnosis(realtimeSummary.diagnosis?.data)
      setDetailedFindings(realtimeSummary.detailedFindings?.data)
      if (typeof realtimeSummary.differentialDiagnosis?.data !== "object") {
        setDifferentialDiagnosis(
          realtimeSummary.differentialDiagnosis?.data ||
          realtimeSummary.differentialDiagnosis?.data?.conditions ||
          []
        )
      }
      setMedicationAnalysis(realtimeSummary.medicationAnalysis?.data)
      setOptimizedTreatmentPlan(realtimeSummary.optimizedTreatmentPlan?.data)
      setTreatmentPlan(realtimeSummary.treatmentPlan?.data)
    }
  }, [realtimeSummary])
  return (
    <div className="flex flex-col bg-zinc-50 text-gray-800">
      <div className="flex-1 overflow-y-auto">
        <Tabs defaultValue="ai-summary" className="space-y-6">
          <TabsList className="bg-white border-b w-full justify-start rounded-none p-0 h-auto flex flex-wrap">
            <TabsTrigger
              value="ai-summary"
              className="rounded-none border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:text-primary px-4 py-3 text-sm sm:text-sm"
            >
              AI Summary
            </TabsTrigger>
            <TabsTrigger
              value="differential"
              className="rounded-none border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:text-primary px-4 py-3 text-sm sm:text-sm"
            >
              Differential Diagnosis
            </TabsTrigger>
            <TabsTrigger
              value="medications"
              className="rounded-none border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:text-primary px-4 py-3 text-sm sm:text-sm"
            >
              Medications
            </TabsTrigger>
            <TabsTrigger
              value="treatment"
              className="rounded-none border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:text-primary px-4 py-3 text-sm sm:text-sm"
            >
              Treatment Plan
            </TabsTrigger>
            {summary.state_data?.scratchpad &&
              Object.keys(summary.state_data.scratchpad)
                .filter((key) => !tabsBlacklist.includes(key))
                .map((key, idx) => {
                  // if (key !== "Summary") {
                  return (
                    <TabsTrigger
                      key={idx}
                      value={key}
                      className="rounded-none border-b-2 border-transparent data-[state=active]:border-primary data-[state=active]:text-primary px-4 py-3 text-sm sm:text-sm"
                    >
                      {key}
                    </TabsTrigger>
                  )
                  // }
                  // return null
                })}
          </TabsList>

          {/* AI Summary Tab */}
          <TabsContent value="ai-summary" className="space-y-6 px-0">
            {/* Quick Stats Cards */}
            <div className="grid gap-4 grid-cols-2 lg:grid-cols-4">
              <Card className="bg-white">
                <CardContent className="p-4">
                  <div className="flex items-center justify-between space-y-0">
                    <div className="space-y-1">
                      <p className="text-sm font-medium text-gray-500">
                        Diagnosis Confidence
                      </p>
                      <p className="text-2xl font-bold">
                        {diagnosis.confidence}
                        {typeof diagnosis.confidence === "number" ? "%" : ""}
                      </p>
                    </div>
                    <div className="rounded-full p-2 bg-primary/10">
                      <Stethoscope className="h-4 w-4 text-primary" />
                    </div>
                  </div>
                  <p className="text-xs text-gray-500 mt-2">
                    Based on {diagnosis.tags.length} symptoms
                  </p>
                </CardContent>
              </Card>

              <Card className="bg-white">
                <CardContent className="p-4">
                  <div className="flex items-center justify-between space-y-0">
                    <div className="space-y-1">
                      <p className="text-sm font-medium text-gray-500">
                        Treatment Progress
                      </p>
                      <p className="text-2xl font-bold">{0}%</p>
                    </div>
                    <div className="rounded-full p-2 bg-green-100">
                      <ClipboardCheck className="h-4 w-4 text-green-600" />
                    </div>
                  </div>
                  <p className="text-xs text-gray-500 mt-2">
                    {treatmentPlan?.immediateActions?.length} actions pending
                  </p>
                </CardContent>
              </Card>

              <Card className="bg-white">
                <CardContent className="p-4">
                  <div className="flex items-center justify-between space-y-0">
                    <div className="space-y-1">
                      <p className="text-sm font-medium text-gray-500">
                        Medication Compliance
                      </p>
                      <p className="text-2xl font-bold">80%</p>
                    </div>
                    <div className="rounded-full p-2 bg-blue-100">
                      <Pill className="h-4 w-4 text-blue-600" />
                    </div>
                  </div>
                  <p className="text-xs text-gray-500 mt-2">
                    {medicationAnalysis?.length} active medications
                  </p>
                </CardContent>
              </Card>

              <Card className="bg-white">
                <CardContent className="p-4">
                  <div className="flex items-center justify-between space-y-0">
                    <div className="space-y-1">
                      <p className="text-sm font-medium text-gray-500">
                        Risk Level
                      </p>
                      <p className="text-2xl font-bold">{diagnosis.severity}</p>
                    </div>
                    <div className="rounded-full p-2 bg-red-100">
                      <Heart className="h-4 w-4 text-red-600" />
                    </div>
                  </div>
                  <p className="text-xs text-gray-500 mt-2">
                    Based on current diagnosis
                  </p>
                </CardContent>
              </Card>
            </div>

            {/* Next Steps Alert */}
            {treatmentPlan?.immediateActions?.length > 0 && (
              <Card className="bg-amber-50 border-amber-200">
                <CardContent className="p-4">
                  <div className="flex items-start gap-4">
                    <div className="rounded-full p-2 bg-amber-100 mt-1">
                      <AlertCircle className="h-4 w-4 text-amber-600" />
                    </div>
                    <div>
                      <h3 className="font-medium text-amber-900 mb-1">
                        Immediate Actions Required
                      </h3>
                      <ul className="space-y-1">
                        {isTreateMentPlanArray &&
                          treatmentPlan?.immediateActions
                            ?.slice(0, 2)
                            .map((action, idx) => (
                              <li key={idx} className="text-sm text-amber-800">
                                {action}
                              </li>
                            ))}
                        {treatmentPlan?.immediateActions?.length > 2 && (
                          <li className="text-sm text-amber-800">
                            +{treatmentPlan?.immediateActions?.length - 2} more
                            actions
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}
            <div className="grid gap-6 lg:grid-cols-2">
              {/* Primary Diagnosis Card */}
              <Card>
                <CardHeader className="border-b p-4">
                  <CardTitle className="flex items-center justify-between text-lg font-bold">
                    <div className="flex items-center gap-2">
                      <div className="rounded-full bg-primary p-1.5">
                        <Activity className="h-4 w-4 text-primary-foreground" />
                      </div>
                      Primary Diagnosis
                    </div>
                    <Button size="icon" variant="ghost">
                      <MoreHorizontal className="h-4 w-4" />
                    </Button>
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-6 space-y-4">
                  <div>
                    <div className="mb-2 text-lg font-semibold">
                      {diagnosis.condition}
                    </div>
                    <div className="mb-1 text-sm font-medium">
                      Severity: {diagnosis.severity}
                    </div>
                    <Progress
                      value={parseInt(diagnosis.confidence)}
                      className="h-2 rounded-full bg-gray-200"
                    />
                    <div className="mt-1 text-sm text-gray-500">
                      AI Confidence: {diagnosis.confidence}
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="text-sm font-semibold">
                      Clinical Evaluation and Review of Symptoms
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {diagnosis.tags.map((tag, idx) => (
                        <Badge key={idx}>{tag}</Badge>
                      ))}
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="text-sm">
                      <span className="font-semibold">Description:</span>{" "}
                      {diagnosis.description}
                    </div>
                    <div className="space-y-1">
                      <div className="text-sm font-medium">
                        Key Observations:
                      </div>
                      <ul className="list-inside list-disc text-sm text-gray-500">
                        {Array.isArray(diagnosis?.keyObservations) &&
                          diagnosis.keyObservations.map((observation, idx) => (
                            <li key={idx}>{observation}</li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* Symptom Analysis Card */}
              <Card>
                <CardHeader className="border-b p-4">
                  <CardTitle className="flex items-center justify-between text-lg font-bold">
                    <div className="flex items-center gap-2">
                      <div className="rounded-full bg-primary p-1.5">
                        <Calendar className="h-4 w-4 text-primary-foreground" />
                      </div>
                      Symptom Analysis
                    </div>
                    <Button size="icon" variant="ghost">
                      <MoreHorizontal className="h-4 w-4" />
                    </Button>
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-6 space-y-6">
                  <div className="space-y-2">
                    <div className="text-sm font-semibold">
                      Primary Characteristics
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {isDetailedFindingArray &&
                        detailedFindings?.keyCharacteristics?.map(
                          (finding, idx) => <Badge key={idx}>{finding}</Badge>
                        )}
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="text-sm font-semibold">
                      Associated Findings
                    </div>
                    <ul className="list-inside list-disc text-sm text-gray-600">
                      {isDetailedFindingArray &&
                        detailedFindings?.associatedFindings?.map(
                          (finding, idx) => <li key={idx}>{finding}</li>
                        )}
                    </ul>
                  </div>
                  <div className="space-y-2">
                    <div className="text-sm font-semibold">
                      Distribution Patterns
                    </div>
                    <ul className="list-inside list-disc text-sm text-gray-600">
                      {isDetailedFindingArray &&
                        detailedFindings?.distributionPatterns?.map(
                          (pattern, idx) => <li key={idx}>{pattern}</li>
                        )}
                    </ul>
                  </div>
                </CardContent>
              </Card>
            </div>
          </TabsContent>

          {/* Treatment Plan Tab */}
          <TabsContent value="treatment" className="space-y-6">
            {/* Overview Card */}
            <Card>
              <CardHeader className="p-4 border-b">
                <CardTitle className="text-lg font-bold flex items-center gap-2">
                  <CalendarIcon className="h-5 w-5 text-primary" />
                  Treatment Timeline
                </CardTitle>
                <p className="text-sm text-gray-500">
                  Comprehensive treatment strategy and timeline
                </p>
              </CardHeader>
              <CardContent className="p-6">
                <div className="grid gap-6 md:grid-cols-2">
                  {/* Immediate Actions */}
                  <div className="space-y-4">
                    <div className="flex items-center gap-2 mb-4">
                      <AlertCircle className="h-5 w-5 text-red-500" />
                      <h3 className="font-semibold text-red-500">
                        Immediate Actions Required
                      </h3>
                    </div>
                    <div className="space-y-3">
                      {isTreateMentPlanArray &&
                        treatmentPlan?.immediateActions?.map((action, idx) => (
                          <div
                            key={idx}
                            className="flex items-start gap-3 p-3 bg-red-50 rounded-lg border border-red-100"
                          >
                            <div className="mt-1 h-2 w-2 rounded-full bg-red-500 shrink-0" />
                            <span className="text-sm text-gray-700">
                              {action}
                            </span>
                          </div>
                        ))}
                    </div>
                    {/* Long-term Goals */}
                    <div className="mt-8">
                      <div className="flex items-center gap-2 mb-4">
                        <CheckCircle2 className="h-5 w-5 text-green-500" />
                        <h3 className="font-semibold text-green-700">
                          Long-term Treatment Goals
                        </h3>
                      </div>
                      <div className="grid gap-4 md:grid-cols-3">
                        {isTreateMentPlanArray &&
                          treatmentPlan?.longTermActions?.map((action, idx) => (
                            <div
                              key={idx}
                              className="p-4 bg-green-50 rounded-lg border border-green-100"
                            >
                              <span className="text-sm text-gray-700">
                                {action}
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  {/* Treatment Progress */}
                  <div className="space-y-4">
                    <div className="flex items-center gap-2 mb-4">
                      <TimeIcon className="h-5 w-5 text-primary" />
                      <h3 className="font-semibold">Treatment Progress</h3>
                    </div>
                    <div className="space-y-4">
                      {optimizedTreatmentPlan?.treatments &&
                        optimizedTreatmentPlan.treatments
                          .sort((a, b) => b.priority - a.priority)
                          .map((treatment, idx) => (
                            <div key={idx} className="relative">
                              <div className="flex items-center gap-3 mb-2">
                                <Badge
                                  variant={
                                    treatment.priority > 7
                                      ? "destructive"
                                      : "outline"
                                  }
                                >
                                  Priority {treatment.priority}
                                </Badge>
                                <span className="text-sm text-gray-500">
                                  {treatment.timeline}
                                </span>
                              </div>
                              <div className="p-3 bg-white rounded-lg border shadow-sm">
                                <p className="text-sm font-medium">
                                  {treatment.treatment}
                                </p>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>

            {/* Treatment Guidelines Card */}
            <Card>
              <CardHeader className="p-4 border-b">
                <CardTitle className="text-lg font-bold">
                  Treatment Guidelines
                </CardTitle>
              </CardHeader>
              <CardContent className="p-6">
                <div className="grid gap-6 md:grid-cols-2">
                  <div className="space-y-4">
                    <h3 className="font-semibold">Aesthetic Considerations</h3>
                    <div className="space-y-3">
                      {isTreateMentPlanArray &&
                        treatmentPlan?.aestheticActions?.map((action, idx) => (
                          <div
                            key={idx}
                            className="flex items-start gap-3 p-3 bg-gray-50 rounded-lg"
                          >
                            <div className="mt-1 h-2 w-2 rounded-full bg-primary shrink-0" />
                            <span className="text-sm">{action}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="space-y-4">
                    <h3 className="font-semibold">Treatment Precautions</h3>
                    <div className="space-y-3">
                      {isTreateMentPlanArray &&
                        treatmentPlan?.longTermActions?.map((action, idx) => (
                          <div
                            key={idx}
                            className="flex items-start gap-3 p-3 bg-gray-50 rounded-lg"
                          >
                            <AlertTriangle className="h-4 w-4 text-yellow-500 shrink-0" />
                            <span className="text-sm">{action}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          {/* Medications Tab */}
          <TabsContent value="medications" className="space-y-6">
            {/*  Medications Overview */}
            <Card>
              <CardHeader className="p-4 border-b">
                <CardTitle className="text-lg font-bold flex items-center gap-2">
                  <Pill className="h-5 w-5 text-primary" />
                  Medications
                </CardTitle>
              </CardHeader>
              <CardContent className="p-6">
                <div className="grid gap-6">
                  {medicationAnalysis?.map((medication, idx) => (
                    <div
                      key={idx}
                      className="p-4 bg-white rounded-lg border shadow-sm"
                    >
                      <div className="flex flex-wrap items-start justify-between gap-4 mb-4">
                        <div>
                          <h4 className="text-lg font-semibold">
                            {medication.name}
                          </h4>
                          <p className="text-sm text-gray-500">
                            {medication.dosage}
                          </p>
                        </div>
                        <Badge variant="outline" className="text-sm">
                          Effectiveness: {medication.effectiveness}
                        </Badge>
                      </div>

                      <div className="grid gap-4 md:grid-cols-2">
                        <div className="space-y-2">
                          <p className="text-sm font-semibold flex items-center gap-2">
                            <AlertCircle className="h-4 w-4 text-yellow-500" />
                            Contraindications
                          </p>
                          <ul className="space-y-1">
                            {Array.isArray(medication?.contraindications) ? (
                              medication.contraindications.map((contra, i) => (
                                <li
                                  key={i}
                                  className="text-sm text-gray-600 flex items-start gap-2"
                                >
                                  <span className="mt-1.5 h-1 w-1 rounded-full bg-yellow-500 shrink-0" />
                                  {contra}
                                </li>
                              ))
                            ) : (
                              <li className="text-sm text-gray-600 flex items-start gap-2">
                                <span className="mt-1.5 h-1 w-1 rounded-full bg-yellow-500 shrink-0" />
                                {medication?.contraindications ||
                                  "No contraindications specified"}
                              </li>
                            )}
                          </ul>
                        </div>

                        <div className="space-y-2">
                          <p className="text-sm font-semibold flex items-center gap-2">
                            <AlertTriangle className="h-4 w-4 text-red-500" />
                            Interactions
                          </p>
                          <ul className="space-y-1">
                            {Array.isArray(medication?.interactions) ? (
                              medication.interactions.map((interaction, i) => (
                                <li
                                  key={i}
                                  className="text-sm text-gray-600 flex items-start gap-2"
                                >
                                  <span className="mt-1.5 h-1 w-1 rounded-full bg-red-500 shrink-0" />
                                  {interaction}
                                </li>
                              ))
                            ) : (
                              <li className="text-sm text-gray-600 flex items-start gap-2">
                                <span className="mt-1.5 h-1 w-1 rounded-full bg-red-500 shrink-0" />
                                {medication?.interactions ||
                                  "No interactions specified"}
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>

            {/* Patient Medication History */}
            <Card>
              <CardHeader className="p-4 border-b">
                <CardTitle className="text-lg font-bold">
                  Patient Medication History
                </CardTitle>
              </CardHeader>
              <CardContent className="p-6">
                <div className="grid gap-6 md:grid-cols-2">
                  {/* <div className="space-y-3">
                    <h3 className="font-semibold">Previous Medications</h3>
                    <div className="space-y-2">
                      {medicationAnalysis.patientData.currentMedications.map((med, idx) => (
                        <div key={idx} className="p-3 bg-gray-50 rounded-lg">
                          <p className="text-sm font-medium">{med}</p>
                        </div>
                      ))}
                    </div>
                  </div> */}

                  {/* <div className="space-y-3">
                    <h3 className="font-semibold">Relevant Medical History</h3>
                    <div className="space-y-2">
                      {medicationAnalysis.patientData.medicalHistory.map((history, idx) => (
                        <div key={idx} className="p-3 bg-gray-50 rounded-lg">
                          <p className="text-sm text-gray-600">{history}</p>
                        </div>
                      ))}
                    </div>
                  </div> */}
                </div>

                {/* Allergies Section */}
                {/* <div className="mt-6">
                  <h3 className="font-semibold mb-3">Known Allergies and Sensitivities</h3>
                  <div className="p-4 bg-red-50 rounded-lg border border-red-100">
                    <div className="flex flex-wrap gap-2">
                      {medicationAnalysis.patientData.allergies.map((allergy, idx) => (
                        <Badge key={idx} variant="destructive" className="text-sm">
                          {allergy}
                        </Badge>
                      ))}
                    </div>
                  </div>
                </div> */}

                {/* Medication Confidence Score */}
                {/* <div className="mt-6">
                  <div className="flex items-center justify-between mb-2">
                    <h3 className="font-semibold">AI Medication Analysis Confidence</h3>
                    <Badge variant="outline">{medicationAnalysis.aiConfidenceScore}%</Badge>
                  </div>
                  <Progress
                    value={medicationAnalysis.aiConfidenceScore}
                    className="h-2 rounded-full bg-gray-200"
                  />
                </div> */}
              </CardContent>
            </Card>
          </TabsContent>

          {/* Differential Diagnosis Tab - Remains the same */}
          <TabsContent value="differential" className="space-y-6">
            <Card>
              <CardHeader className="p-4 border-b">
                <CardTitle className="text-lg font-bold">
                  Differential Diagnosis
                </CardTitle>
              </CardHeader>
              <CardContent className="p-6">
                <div className="space-y-6">
                  {differentialDiagnosis?.map((diagnosis, idx) => (
                    <div
                      key={idx}
                      className="border-b pb-4 last:border-0 last:pb-0"
                    >
                      <div className="flex items-center justify-between mb-2">
                        <h4 className="font-medium">{diagnosis.condition}</h4>
                        <Badge variant="outline">{diagnosis.percentage}%</Badge>
                      </div>
                      <Progress
                        value={diagnosis.percentage}
                        className="h-2 rounded-full bg-gray-200 mb-2"
                      />
                      <p className="text-sm text-gray-500">{diagnosis.bio}</p>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          {/* Dynamic Tabs Content */}
          {summary.state_data?.scratchpad &&
            Object.keys(summary.state_data.scratchpad)
              .filter((key) => !tabsBlacklist.includes(key))
              .map((key, idx) => {
                const md =
                  key === "Summary"
                    ? summary.markdown_report
                    : summary.state_data.scratchpad[key]
                return (
                  <TabsContent key={idx} value={key} className="space-y-6">
                    <Card className="p-6">
                      <MarkdownPreview
                        source={md || "No Data Source"}
                        wrapperElement={{
                          "data-color-mode": "light",
                        }}
                      />
                    </Card>
                  </TabsContent>
                )
              })}
        </Tabs>
      </div>
    </div>
  )
}
