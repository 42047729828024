import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"

export default function RootCanalMeasurements() {
  return (
    <div className="min-h-screen bg-white text-black flex flex-col">
      <main className="flex-grow container mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold mb-8 text-center">Measurement Details</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <Card className="bg-white border border-gray-200">
            <CardHeader>
              <CardTitle className="text-xl font-semibold">Average Canal Lengths</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Tooth Type</TableHead>
                    <TableHead>Average Length (mm)</TableHead>
                    <TableHead>Range (mm)</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>Maxillary Central Incisor</TableCell>
                    <TableCell>23.0</TableCell>
                    <TableCell>20.5 - 25.5</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Maxillary Lateral Incisor</TableCell>
                    <TableCell>22.0</TableCell>
                    <TableCell>19.5 - 24.5</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Maxillary Canine</TableCell>
                    <TableCell>26.5</TableCell>
                    <TableCell>24.0 - 29.0</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Maxillary First Premolar</TableCell>
                    <TableCell>20.5</TableCell>
                    <TableCell>18.0 - 23.0</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mandibular First Molar</TableCell>
                    <TableCell>21.0</TableCell>
                    <TableCell>18.5 - 23.5</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card className="bg-white border border-gray-200">
            <CardHeader>
              <CardTitle className="text-xl font-semibold">Apical Constriction</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="mb-4">The apical constriction is the narrowest part of the root canal and is considered the ideal termination point for root canal treatment.</p>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Measurement</TableHead>
                    <TableHead>Value</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>Average distance from apex</TableCell>
                    <TableCell>0.5 - 1.0 mm</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Diameter range</TableCell>
                    <TableCell>0.2 - 0.4 mm</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card className="bg-white border border-gray-200">
            <CardHeader>
              <CardTitle className="text-xl font-semibold">Canal Taper</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="mb-4">Canal taper refers to the gradual increase in diameter from the apical constriction to the canal orifice.</p>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Taper Type</TableHead>
                    <TableHead>Description</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>Standard</TableCell>
                    <TableCell>0.02 mm/mm (2%)</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Greater Taper</TableCell>
                    <TableCell>0.04 - 0.12 mm/mm (4% - 12%)</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card className="bg-white border border-gray-200">
            <CardHeader>
              <CardTitle className="text-xl font-semibold">Working Length Determination</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="mb-4">Working length is the distance from a coronal reference point to the point at which canal preparation and filling should terminate.</p>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Method</TableHead>
                    <TableHead>Accuracy</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>Electronic Apex Locator</TableCell>
                    <TableCell>±0.5 mm</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Radiographic</TableCell>
                    <TableCell>±1.0 mm</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </div>
        <Card className="mt-8 bg-white border border-gray-200">
          <CardHeader>
            <CardTitle className="text-xl font-semibold">Key Considerations for Root Canal Measurements</CardTitle>
          </CardHeader>
          <CardContent>
            <ul className="list-disc list-inside space-y-2 text-gray-700">
              <li>Individual tooth anatomy can vary significantly from average measurements.</li>
              <li>Curved canals may require special techniques for accurate length determination.</li>
              <li>The apical foramen often does not coincide with the anatomical apex of the root.</li>
              <li>Over-instrumentation beyond the apical constriction can lead to post-operative complications.</li>
              <li>Regular calibration of measurement devices is crucial for maintaining accuracy.</li>
            </ul>
          </CardContent>
        </Card>
      </main>
      <footer className="border-t border-gray-200 py-4 mt-12">
        <div className="container mx-auto px-4 text-center text-gray-600">
          © 2024 Vitalview AI Insights. All rights reserved.
        </div>
      </footer>
    </div>
  )
}