import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import MarkdownPreview from '@uiw/react-markdown-preview'

interface ReportViewerProps {
  title: string
  content: string
}

export function ReportViewer({ title, content }: ReportViewerProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm">
          View Report
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[75vw] max-h-[90vh] overflow-y-auto">
        <DialogHeader className="pb-4 border-b">
          <DialogTitle className="text-2xl font-semibold tracking-tight">
            {title}
          </DialogTitle>
        </DialogHeader>
        <div className="mt-6 px-6">
          <MarkdownPreview 
            source={content} 
            className="markdown-preview"
            wrapperElement={{
              "data-color-mode": "light"
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}