import {
  NotepadText,
  Microscope,
  LibraryBig,
  ListTodo,
  BookOpen,
  Settings2,
  LifeBuoy,
  Send,
  Frame,
} from "lucide-react"

export const data = {
  user: {
    name: "amalshehu",
    email: "we@vitalview.ai",
    avatar: "/avatars/amalshehu.jpg",
  },
  navMain: [
    {
      title: "Cases",
      url: "/cases",
      icon: NotepadText,
      isActive: true,
      items: [],
    },
    {
      title: "Analyze",
      url: "/analyze",
      icon: Microscope,
      items: [
        // {
        //   title: "X-rays",
        //   url: "/analyze/x-rays",
        // },
        // {
        //   title: "Documents",
        //   url: "/analyze/documents",
        // },
        // {
        //   title: "Images",
        //   url: "/analyze/images",
        // },
      ],
    },
    {
      title: "Research and Study",
      url: "/research",
      icon: LibraryBig,
      items: [
        {
          title: "Research Papers",
          url: "/research/papers",
        },
        // {
        //   title: "Clinical Studies",
        //   url: "/research/studies",
        // },
      ],
    },
    {
      title: "Collaborative Tools",
      url: "/collaborative-tools",
      icon: ListTodo,
      items: [
        // {
        //   title: "Team Comments",
        //   url: "/collaborative-tools/comments",
        // },
        {
          title: "Notes",
          url: "/collaborative-tools/notes",
        },
      ],
    },
    // {
    //   title: "Documentation",
    //   url: "/documentation",
    //   icon: BookOpen,
    //   items: [
    //     {
    //       title: "Get Started",
    //       url: "/documentation/get-started",
    //     },
    //     {
    //       title: "Tutorials",
    //       url: "/documentation/tutorials",
    //     },
    //   ],
    // },
    {
      title: "Settings",
      url: "/settings/billing",
      icon: Settings2,
      items: [
        // {
        //   title: "General",
        //   url: "/settings/general",
        // },
        // {
        //   title: "Team",
        //   url: "/settings/team",
        // },
        {
          title: "Billing",
          url: "/settings/billing",
        },
        // {
        //   title: "Limits",
        //   url: "/settings/limits",
        // },
      ],
    },
  ],
  navSecondary: [
    {
      title: "Support",
      url: "/support",
      icon: LifeBuoy,
    },
    {
      title: "Feedback",
      url: "/feedback",
      icon: Send,
    },
  ],
  projects: [
    {
      name: "DENTAL-CASE-001",
      url: "/projects/dental-case-001",
      icon: Frame,
    },
  ],
}
