import { useState, useEffect, useRef } from "react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { ReportViewer } from "@/components/ReportViewer"
import { Search, Edit, Trash2, Download } from "lucide-react"
import { Link } from "react-router-dom"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import axios from "axios" // Make sure to install axios if you haven't already
import { useAuth } from "@/context/AuthContext"

interface ResearchProject {
  research_id: string
  query: string
  research_name?: string
  status: "completed" | "in-progress" | "failed"
  download_url: string
  timestamp: string
  audience: string
  duration: number
  tone: string
  report_content?: string
}

export default function ViewLibrary() {
  const [searchQuery, setSearchQuery] = useState("")
  const [projects, setProjects] = useState<ResearchProject[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [isInitialFetch, setIsInitialFetch] = useState(true)
  const [currentEditId, setCurrentEditId] = useState<string | null>(null)
  const [editName, setEditName] = useState("")
  const [isUpdating, setIsUpdating] = useState(false)
  const { user } = useAuth()

  // Replace these with actual user and case IDs
  const userId = user?.uid
  const caseId = "default"

  const hasFetchedInitially = useRef(false)

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        // Only set loading to true for initial fetch
        if (isInitialFetch) {
          console.log("Initial fetch")
          setLoading(true)
        }

        const response = await axios.get(`https://api.vitalviewai.com/researches/${userId}/${caseId}`)
        console.log("Projects fetched:", response.data.researches)
        setProjects(response.data.researches)
        setError(null)
      } catch (err) {
        console.error("Error fetching projects:", err)
        setError("Failed to load projects. Please try again later.")
      } finally {
        if (isInitialFetch) {
          setLoading(false)
          setIsInitialFetch(false)
        }
      }
    }

    // Handle research completion event
    const handleResearchCompleted = (event: CustomEvent) => {
      const { researchId, status, data, error } = event.detail

      console.log(`Received event for research ${researchId} with status: ${status}`)

      if (status === "completed") {
        console.log(`Research ${researchId} completed successfully. Message from event handler`)
        fetchProjects()
      } else if (status === "failed") {
        console.error(`Research ${researchId} failed: ${error}. Message from event handler`)
        // Optionally, update the UI to reflect the failure
      }
    }

    console.log("useEffect triggered")

    if (!hasFetchedInitially.current) {
      console.log("Initial fetch")
      fetchProjects()
      hasFetchedInitially.current = true
    }

    // Second fetch after 5 seconds
    const secondFetchTimeout = setTimeout(() => {
      console.log("The 5 second fetch")
      fetchProjects()
    }, 5000)

    // Second fetch after 5 seconds
    const thirdFetchTimeout = setTimeout(() => {
      console.log("The 30 second fetch")
      fetchProjects()
    }, 30000)

    // Add event listener for research completion
    window.addEventListener('researchCompleted', handleResearchCompleted as EventListener)

    return () => {
      clearTimeout(secondFetchTimeout)
      clearTimeout(thirdFetchTimeout)
      window.removeEventListener('researchCompleted', handleResearchCompleted as EventListener)
    }
  }, [userId, caseId, isInitialFetch])

  const filteredProjects = projects.filter(project =>
    project.query.toLowerCase().includes(searchQuery.toLowerCase())
  )
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())

  const deleteProject = async (id: string) => {
    try {
      await axios.delete(`https://api.vitalviewai.com/research/${userId}/${caseId}/${id}`)
      setProjects(projects.filter(project => project.research_id !== id))
    } catch (error) {
      console.error("Error deleting project:", error)
      // Optionally, add user-facing error handling here
    }
  }

  const handleUpdateResearchName = async () => {
    if (!currentEditId) return

    try {
      setIsUpdating(true)
      await axios.patch(
        `https://api.vitalviewai.com/research/${userId}/${caseId}/${currentEditId}/name`,
        { new_name: editName }
      )

      // Update the local state
      setProjects(projects.map(project =>
        project.research_id === currentEditId
          ? { ...project, research_name: editName }
          : project
      ))

      return true
    } catch (error) {
      console.error("Error updating research name:", error)
      return false
    } finally {
      setIsUpdating(false)
    }
  }

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  return (
    <div className="container mx-auto p-6 w-full">
      <header className="flex justify-between items-center mb-6">
        <h1 className="text-3xl text-zinc-800 font-semibold">Research Library</h1>
        <Link to="/research">
          <Button variant="outline">New Research</Button>
        </Link>
      </header>

      <div className="relative mb-6">
        <Input
          type="text"
          placeholder="Search for a research project"
          className="pl-10 pr-4 py-2 w-full"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" />
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {filteredProjects.map((project) => (
          <Card key={project.research_id} className="flex flex-col shadow-none">
            <CardHeader>
              <CardTitle className="text-lg">
                {project.research_name || project.query}
              </CardTitle>
            </CardHeader>
            <CardContent className="flex-grow">
              <p className="text-sm text-muted-foreground mb-2">Created on: {new Date(project.timestamp).toLocaleString()}</p>
              <div className="flex flex-wrap gap-2 mb-2">
                <Badge variant="secondary">{project.audience}</Badge>
                {project.duration && (
                  <Badge variant="secondary">{project.duration} min</Badge>
                )}
                <Badge variant="secondary">{project.tone}</Badge>
              </div>
              <Badge variant={
                project.status === "completed" ? "default" :
                  project.status === "in-progress" ? "outline" :
                    "destructive"
              }>
                {project.status}
              </Badge>
            </CardContent>
            <CardFooter className="flex justify-between">
              {project.report_content && (
                <ReportViewer
                  title={project.research_name || project.query}
                  content={project.report_content}
                />
              )}
              <Dialog onOpenChange={(isOpen) => {
                if (isOpen) {
                  setCurrentEditId(project.research_id)
                  setEditName(project.research_name || project.query)
                } else {
                  setCurrentEditId(null)
                }
              }}>
                <DialogTrigger asChild>
                  <Button variant="outline" size="sm">
                    <Edit className="w-4 h-4 mr-2" />
                    Edit
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Edit Research Project</DialogTitle>
                    <DialogDescription>
                      Make changes to your research project here. Click save when you're done.
                    </DialogDescription>
                  </DialogHeader>
                  <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor={`title-${project.research_id}`} className="text-right">
                        Title
                      </Label>
                      <Input
                        id={`title-${project.research_id}`}
                        value={editName}
                        className="col-span-3"
                        onChange={(e) => setEditName(e.target.value)}
                      />
                    </div>
                  </div>
                  <DialogFooter>
                    <Button
                      onClick={async () => {
                        const success = await handleUpdateResearchName()
                        if (success) {
                          const closeButton = document.querySelector('[data-dialog-close]') as HTMLButtonElement
                          if (closeButton) closeButton.click()
                        }
                      }}
                      disabled={isUpdating}
                    >
                      {isUpdating ? "Saving..." : "Save changes"}
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
              <div className="space-x-2">
                {/* the export button is hidden for now  */}
                {project.download_url && (
                  <a href={project.download_url} target="_blank" rel="noopener noreferrer" download>
                    <Button variant="outline" size="sm">
                      <Download className="w-4 h-4 mr-2" />
                      Export
                    </Button>
                  </a>
                )}
                <Button variant="destructive" size="sm" onClick={() => deleteProject(project.research_id)}>
                  <Trash2 className="w-4 h-4" />
                </Button>
              </div>
            </CardFooter>
          </Card>
        ))}
      </div>

      {filteredProjects.length === 0 && (
        <div className="text-center text-muted-foreground mt-8">
          No research projects found. Try adjusting your search or create a new project.
        </div>
      )}
    </div>
  )
}