// @ts-nocheck
import { useState } from "react"
import { Badge } from "./ui/badge"
import { Toggle } from "@/components/ui/toggle" // Importing ShadCN's Toggle component
import { FlaskConicalIcon, TextSearch } from "lucide-react"
const HighlightedCaseDetail = ({ caseData, symptoms }) => {
  console.log('symptoiweuriwoeurweoiurewuiorms:', symptoms)
  const [highlightsEnabled, setHighlightsEnabled] = useState(false)
  const text = caseData?.caseDetail || ""

  // Define consistent styles for each entity group
  const entityStyles = {
    SEX: "bg-blue-100 text-blue-800",
    CLINICAL_EVENT: "bg-green-100 text-green-800",
    DIAGNOSTIC_PROCEDURE: "bg-purple-100 text-purple-800",
    LAB_VALUE: "bg-yellow-100 text-yellow-800",
    SIGN_SYMPTOM: "bg-red-100 text-red-800",
    DETAILED_DESCRIPTION: "bg-indigo-100 text-indigo-800",
    DISEASE_DISORDER: "bg-teal-100 text-teal-800",
    NONBIOLOGICAL_LOCATION: "bg-pink-100 text-pink-800",
  }

  const entityGroupStyles = {
    SEX: "bg-blue-300 text-blue-800",
    CLINICAL_EVENT: "bg-green-300 text-green-800",
    DIAGNOSTIC_PROCEDURE: "bg-purple-300 text-purple-800",
    LAB_VALUE: "bg-yellow-300 text-yellow-800",
    SIGN_SYMPTOM: "bg-red-300 text-red-800",
    DETAILED_DESCRIPTION: "bg-indigo-300 text-indigo-800",
    DISEASE_DISORDER: "bg-teal-300 text-teal-800",
    NONBIOLOGICAL_LOCATION: "bg-pink-300 text-pink-800",
  }

  // Helper function to generate random colors
  const generateRandomColor = () => {
    const colors = [
      "bg-zinc-100 text-zinc-800",
      "bg-orange-100 text-orange-800",
      "bg-lime-100 text-lime-800",
      "bg-amber-100 text-amber-800",
      "bg-cyan-100 text-cyan-800",
      "bg-emerald-100 text-emerald-800",
    ]
    return colors[Math.floor(Math.random() * colors.length)]
  }

  // Toggle function to enable or disable highlights
  const toggleHighlights = () => {
    if (typeof symptoms === 'string') return
    setHighlightsEnabled((prev) => !prev)
  }

  const getHighlightedText = () => {
    if (!highlightsEnabled) {
      return text
    }

    let highlightedText = []
    let lastIndex = 0

    symptoms?.entities?.forEach((entity, index) => {
      const randomColor = generateRandomColor()
      highlightedText.push(text.slice(lastIndex, entity.start))

      const entityStyle = entityStyles[entity.entity_group] || randomColor
      const entityGroupStyle = entityGroupStyles[entity.entity_group] || randomColor

      highlightedText.push(
        <div
          key={index}
          className={`${entityStyle} px-2 py-0.5 rounded-sm inline-flex items-center mx-1`}
        >
          <span className="text-xs font-medium mr-1">{entity.word}</span>
          <span className="text-xs font-semibold uppercase">
            <Badge
              className={`${entityGroupStyle} text-xs font-semibold`}
              variant="outline"
            >
              {entity.entity_group}
            </Badge>
          </span>
        </div>
      )

      lastIndex = entity.end
    })

    highlightedText.push(text.slice(lastIndex))

    return highlightedText
  }

  return (
    <div className="relative p-4 border rounded-md">
      <div className="absolute bottom-4 right-4">
        {
          typeof symptoms !== 'text' ? (<Toggle
            onClick={toggleHighlights}
            className="border"
            aria-label="Toggle highlight markers"
          >
            <FlaskConicalIcon size={24} />
          </Toggle>) : null
        }
      </div>

      {/* Highlighted Text */}
      <div className="space-y-2">
        {getHighlightedText()}
      </div>
    </div>
  )
}

export default HighlightedCaseDetail
