import { FileText } from "lucide-react"

const FilePreview = ({ fileUrl }) => {
  if (!fileUrl) return null

  const isPDF =
    typeof fileUrl === "string" && fileUrl.toLowerCase().endsWith(".pdf")
  const isImage =
    typeof fileUrl === "string" && /\.(jpg|jpeg|png|gif|webp)$/i.test(fileUrl)

  return (
    <div className="mt-2 rounded-lg border border-gray-200 p-2 max-w-24">
      {isPDF ? (
        <div className="flex items-center gap-2 text-gray-600">
          <FileText className="w-8 h-8" />
          <span className="text-sm">PDF Document</span>
          <a
            href={fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-auto text-sm text-blue-500 hover:text-blue-600"
          >
            View
          </a>
        </div>
      ) : isImage ? (
        <div className="relative group">
          <img
            src={fileUrl}
            alt="Attached file"
            className="w-full h-auto rounded-md object-cover max-h-24"
            onClick={() => window.open(fileUrl, "_blank")}
          />
          <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 transition-all duration-200 flex items-center justify-center cursor-pointer">
            <span className="text-white opacity-0 group-hover:opacity-100 text-sm">
              Click to view full size
            </span>
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-2 text-gray-600">
          <FileText className="w-8 h-8" />
          <span className="text-sm">Attached File</span>
          <a
            href={fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-auto text-sm text-blue-500 hover:text-blue-600"
          >
            View
          </a>
        </div>
      )}
    </div>
  )
}

export default FilePreview
