//@ts-nocheck
import axios from "axios"
import { Button } from "@/components/ui/button"
import { useEffect, useState } from "react"

const PaymentButton = ({
  userName,
  phoneNumber,
  userEmail,
  receiptId,
  amount,
  currency,
  name,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://checkout.razorpay.com/v1/checkout.js"
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const handlePayment = async () => {
    setLoading(true)
    try {
      const order = await axios.post(
        "https://api.vitalviewai.com/create-order",
        {
          amount: `${amount}`,
          currency: `${currency}`,
          receipt: `${receiptId}`,
        }
      )
      const options = {
        key: import.meta.env.VITE_RAZORPAY_KEY_ID,
        amount: order.data.amount,
        currency: order.data.currency,
        name: "Vital View AI",
        description: "Test Transaction",
        order_id: order.data.id,
        handler: function (response) {
          axios
            .post("https://api.vitalviewai.com/verify-payment", {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            })
            .then((res) => {
              if (res.data.status === "ok") {
                alert("Payment successful!")
              } else {
                alert("Payment verification failed")
              }
            })
            .catch((error) => {
              console.error("Error verifying payment:", error)
              alert("Error verifying payment")
            })
        },
        prefill: {
          name: `${userName}`,
          email: `${userEmail}`,
          contact: `${phoneNumber}`,
        },
        notes: {
          address: "Abu Dhabi",
        },
        theme: {
          color: "#000000",
        },
        method: {
          upi: true,
          card: true,
          wallet: false,
          paylater: false,
          netbanking: true,
        },
      }

      // Ensure Razorpay is available
      if (window.Razorpay) {
        const rzp1 = new window.Razorpay(options)
        rzp1.open()
      } else {
        console.error("Razorpay SDK not loaded")
      }
    } catch (error) {
      console.error("Error creating order:", error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      className={`w-full justify-center py-6 text-lg font-semibold disabled:bg-gray-400`}
      disabled={isLoading}
      onClick={handlePayment}
    >
      {isLoading ? "Loading...." : name}
    </Button>
  )
}

export default PaymentButton
