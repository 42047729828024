//@ts-nocheck
import { useEffect, useState } from "react"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { CheckCircle, MoreVertical, XCircle } from "lucide-react"
import axios from "axios"
import { useLocation } from "react-router-dom"
import ReportFeedback from "./ReportFeedback"
import { ScrollArea } from "@/components/ui/scroll-area"
import HighlightedCaseDetail from "@/components/HighlightedCaseDetail"
import CaseWidget from "@/components/CaseWidget"
import { useAuth } from "@/context/AuthContext"
import { realtimeDB } from "@/firebase"
import { onValue, ref, off } from "firebase/database"
import { ChatDrawer } from "@/components/ChatDrawer"
import { Skeleton } from "@/components/ui/skeleton"

export default function CaseDetail() {
  const router = useLocation()
  const id = router.pathname.split("/")[2]
  const [caseData, setCaseData] = useState(null)
  const [summary, setSummary] = useState(null)
  const [loading, setLoading] = useState(true)
  const { user } = useAuth()
  const [realtimeSummary, setRealtimeSummary] = useState(null)

  useEffect(() => {
    let caseRef // Define caseRef outside the block

    if (user) {
      caseRef = ref(realtimeDB, `users/${user.uid}/cases/${id}/summary`)
      const unsubscribe = onValue(caseRef, (snapshot) => {
        const data = snapshot.val()
        setRealtimeSummary(data)
        console.log("Realtime data:", data)
        setLoading(false)
      })

      // Cleanup subscription
      return () => {
        if (caseRef) {
          off(caseRef) // Use `off` from Firebase correctly
        }
      }
    }
  }, [user, id])

  useEffect(() => {
    const fetchCaseData = async () => {
      try {
        const r1 = axios.get(
          `https://api.vitalviewai.com/cases/${user.uid}/${id}`
        )
        const r2 = axios.get(
          `https://api.vitalviewai.com/summary/${user.uid}/${id}`
        )
        const r = await axios.all([r1, r2])
        console.log(r)
        console.log(r[0].data)
        console.log(r[1].data)
        setCaseData(r[0].data?.data.patient || null) // Fallback if data is not present
        setSummary(r[1].data || null)
      } catch (error) {
        console.error("Error fetching case data:", error)
      } finally {
        setLoading(false)
      }
    }
    if (user?.uid) fetchCaseData()
  }, [id, user.uid])

  return (
    <div className="flex flex-col h-full text-black">
      {caseData ? (
        <main className="flex-1 overflow-y-auto p-6 ">
          {/* Patient Header */}
          <div className="bg-white sticky shadow-sm px-4 py-6 rounded-xl mb-6">
            {/* Top Section: Name, Age, Report Feedback */}
            <div className="flex items-center justify-between mb-4">
              <div>
                <h2 className="text-4xl font-semibold text-black">
                  {caseData?.name || "No Name"}
                </h2>
                <div className="flex items-center space-x-2 mt-2">
                  <Badge className="text-xs">
                    {caseData?.age || "N/A"} years old{" "}
                    {caseData?.gender === "F"
                      ? "Female"
                      : caseData?.gender === "M"
                      ? "Male"
                      : caseData?.gender || "N/A"}
                  </Badge>
                </div>
              </div>
              {/* Report Feedback Button */}
              <ReportFeedback />
            </div>

            {/* Highlighted Case Detail */}
            <div>
              <HighlightedCaseDetail
                caseData={caseData}
                symptoms={summary?.state_data?.scratchpad?.SymptomTokens || []}
              />
            </div>
          </div>

          {/* Case Widget */}
          <CaseWidget
            caseData={caseData}
            summary={summary}
            realtimeSummary={realtimeSummary}
          />
        </main>
      ) : (
        <LoadingSkeleton />
      )}
    </div>
  )
}

const LoadingSkeleton = () => {
  return (
    <div className="flex flex-col flex-1 justify-between h-full p-6">
      <Card className="shadow-sm border-slate-200 h-1/6">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <Skeleton className="h-4 w-[100px]" />
        </CardHeader>
        <CardContent>
          <Skeleton className="h-8 w-[60px]" />
        </CardContent>
      </Card>

      <Card className="shadow-sm border-slate-200 h-[80%]">
        {new Array(6).fill(0).map(() => (
          <>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <Skeleton className="h-4 w-full" />
            </CardHeader>
            <CardContent>
              <Skeleton className="h-8 w-1/3" />
            </CardContent>
          </>
        ))}
      </Card>
    </div>
  )
}
