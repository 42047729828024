import * as React from "react"
import { MessageCircle, X, Maximize2, Minimize2 } from "lucide-react"
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { WebSocketProvider } from "@/WebSocketContext"
import ChatPage from "@/pages/ChatPage"

export function ChatDrawer() {
  const [isOpen, setIsOpen] = React.useState(true)
  const [isFullScreen, setIsFullScreen] = React.useState(false)
  const [inputText, setInputText] = React.useState("")
  const [messages, setMessages] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [toggle, setToggle] = React.useState(true)
  const [temperature, setTemperature] = React.useState(0.4)
  const [model, setModel] = React.useState("genesis")
  const [topP, setTopP] = React.useState(0.9)
  const [maxTokens, setMaxTokens] = React.useState(4096)

  const systemMessages = [
    {
      role: "system",
      text: `You are Genesis, an advanced medical assistant specialized in providing detailed and accurate differential diagnoses`,
    },
  ]
  const [selectedSystemMessage, setSelectedSystemMessage] = React.useState(
    systemMessages[0]
  )

  return (
    <>
      {!isOpen && (
        <Button
          className="fixed bottom-4 right-4 h-14 w-14 rounded-full shadow-lg hover:bg-primary/90 transition-all duration-300 ease-in-out z-40"
          onClick={() => setIsOpen(true)}
        >
          <MessageCircle className="h-6 w-6" />
          <span className="sr-only">Open medical chat assistant</span>
        </Button>
      )}
      {isOpen && (
        <div className="z-50">
          <div
            className={`fixed ${isFullScreen
                ? "inset-0"
                : "bottom-4 right-4 w-full max-w-[400px] h-[600px] rounded-lg"
              } bg-background shadow-lg flex flex-col transition-all duration-300 ease-in-out overflow-hidden`}
          >
            <div className="flex justify-between items-center py-2 p-4 bg-primary text-primary-foreground">
              <h2 className="text-lg font-semibold flex items-center gap-2">
                <MessageCircle className="h-5 w-5" />
                AI Assist
              </h2>
              <div className="flex items-center space-x-2">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setIsFullScreen(!isFullScreen)}
                  className="hover:bg-primary/90"
                >
                  {isFullScreen ? (
                    <Minimize2 className="h-5 w-5" />
                  ) : (
                    <Maximize2 className="h-5 w-5" />
                  )}
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setIsOpen(false)}
                  className="hover:bg-primary/90"
                >
                  <X className="h-5 w-5" />
                </Button>
              </div>
            </div>

            <ScrollArea className="flex-1">
              <WebSocketProvider>
                <ChatPage
                  inputText={inputText}
                  setInputText={setInputText}
                  messages={messages}
                  setMessages={setMessages}
                  setLoading={setLoading}
                  loading={loading}
                />
              </WebSocketProvider>
            </ScrollArea>
          </div>
        </div>
      )}
    </>
  )
}
