import React, { createContext, useContext, useState, useEffect } from 'react'
import { onAuthStateChanged, User } from 'firebase/auth'
import { auth } from '../firebase'

// Define the AuthContext type
interface AuthContextType {
  user: User | null
  loading: boolean
}

// Create the authentication context
const AuthContext = createContext<AuthContextType>({ user: null, loading: true })

// AuthProvider component to provide context to children
const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser)
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  return (
    <AuthContext.Provider value={{ user, loading }}>
      {children}
    </AuthContext.Provider>
  )
}

// Custom hook to use the AuthContext
const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
