// src/components/FinishSignUp.tsx
import React, { useEffect, useState } from "react"
import { signInWithEmailLink, isSignInWithEmailLink } from "firebase/auth"
import { auth } from "../firebase"
import { useNavigate } from "react-router-dom"

const FinishSignUp: React.FC = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const storedEmail = window.localStorage.getItem("emailForSignIn")

      if (storedEmail) {
        // Sign in directly with the stored email
        signInWithEmailLink(auth, storedEmail, window.location.href)
          .then(() => {
            window.localStorage.removeItem("emailForSignIn")
            navigate("/") // Redirect to dashboard or home
          })
          .catch((err) => setError(err.message))
      } else {
        // If no email in local storage, ask the user to provide it
        const inputEmail = window.prompt("Please provide your email for confirmation")
        if (inputEmail) {
          signInWithEmailLink(auth, inputEmail, window.location.href)
            .then(() => {
              window.localStorage.removeItem("emailForSignIn")
              navigate("/")
            })
            .catch((err) => setError(err.message))
        }
      }
    }
  }, [navigate])

  return <div>{error ? <p className="text-red-500">{error}</p> : <p>Signing in...</p>}</div>
}

export default FinishSignUp
