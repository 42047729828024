// WebSocketContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'

interface WebSocketContextProps {
  sendMessage: (msg: any) => void
  lastMessage: any
  readyState: ReadyState
}

const WebSocketContext = createContext<WebSocketContextProps | undefined>(undefined)

export const WebSocketProvider = ({ children }) => {
  const socketUrl = 'wss://api.vitalviewai.com/ws/chat'
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => console.log('WebSocket connection opened.'),
    onClose: () => console.log('WebSocket connection closed.'),
    onError: (event) => {
      console.error('WebSocket error:', event)
    },
    shouldReconnect: () => true,
  })

  return (
    <WebSocketContext.Provider value={{ sendMessage: sendJsonMessage, lastMessage, readyState }}>
      {children}
    </WebSocketContext.Provider>
  )
}

export const useWebSocketContext = () => {
  const context = useContext(WebSocketContext)
  if (!context) {
    throw new Error('useWebSocketContext must be used within a WebSocketProvider')
  }
  return context
}
