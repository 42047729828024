import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbLink, BreadcrumbSeparator, BreadcrumbPage } from "@/components/ui/breadcrumb"
import { Slash } from "lucide-react"
import React from "react"
import { Link, useLocation } from "react-router-dom"

export function DynamicBreadcrumb() {
  const location = useLocation()
  const pathSegments = location.pathname.split("/").filter(Boolean) // Split and filter out empty segments

  // Function to capitalize the first letter of each path segment
  const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1)

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem className="hidden md:block">
          <Link to="/" > Dashboard </Link>
        </BreadcrumbItem>
        <BreadcrumbSeparator className="hidden md:block">
          <Slash />
        </BreadcrumbSeparator>

        {pathSegments.map((segment, index) => {
          const to = `/${pathSegments.slice(0, index + 1).join("/")}`
          const isLast = index === pathSegments.length - 1

          return (
            <React.Fragment key={segment}>
              <BreadcrumbItem>
                {isLast ? (
                  <BreadcrumbPage className="font-bold text-zinc-700">{capitalize(segment)}</BreadcrumbPage> // Highlighted style
                ) : (
                  <Link to={pathSegments.slice(0, index + 1).join("/")}>
                    {capitalize(segment)}
                  </Link>
                )}
              </BreadcrumbItem>
              {!isLast && (
                <BreadcrumbSeparator>
                  <Slash />
                </BreadcrumbSeparator>
              )}
            </React.Fragment>
          )
        })}
      </BreadcrumbList>
    </Breadcrumb>
  )
}
