import { useState } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Mic, MicOff, Phone, Mail, MapPin, Calendar, Clock, User, Stethoscope } from 'lucide-react'

export default function MedicalVoiceChat() {
  const [isRecording, setIsRecording] = useState(false)

  const toggleRecording = () => {
    setIsRecording(!isRecording)
  }

  return (
    <div className="grid h-screen grid-cols-1 md:grid-cols-[300px_1fr_300px] gap-4 p-4">
      {/* Patient Information Panel */}
      <Card className="h-full">
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <User className="h-5 w-5" />
            Patient Information
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="flex flex-col items-center gap-2">
            <Avatar className="h-20 w-20">
              <AvatarImage src="/placeholder.svg" alt="Patient" />
              <AvatarFallback>JD</AvatarFallback>
            </Avatar>
            <h3 className="font-semibold">John Doe</h3>
          </div>
          <div className="space-y-3">
            <div className="flex items-center gap-2 text-sm">
              <Phone className="h-4 w-4 text-muted-foreground" />
              <span>+1 (555) 123-4567</span>
            </div>
            <div className="flex items-center gap-2 text-sm">
              <Mail className="h-4 w-4 text-muted-foreground" />
              <span>john.doe@email.com</span>
            </div>
            <div className="flex items-center gap-2 text-sm">
              <MapPin className="h-4 w-4 text-muted-foreground" />
              <span>New York, NY</span>
            </div>
            <div className="flex items-center gap-2 text-sm">
              <Calendar className="h-4 w-4 text-muted-foreground" />
              <span>DOB: 01/15/1980</span>
            </div>
          </div>
          <div className="space-y-2 pt-4">
            <h4 className="text-sm font-semibold">Next Appointment</h4>
            <div className="flex items-center gap-2 text-sm">
              <Clock className="h-4 w-4 text-muted-foreground" />
              <span>March 15, 2024 - 10:00 AM</span>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Diagnosis Assistance Panel */}
      <Card className="h-full flex flex-col">
        <CardHeader className="border-b">
          <CardTitle className="flex items-center justify-between">
            <span>Diagnosis Assistance</span>
            <Button
              variant={isRecording ? "destructive" : "default"}
              size="sm"
              onClick={toggleRecording}
            >
              {isRecording ? <MicOff className="h-4 w-4 mr-2" /> : <Mic className="h-4 w-4 mr-2" />}
              {isRecording ? "Stop Recording" : "Start Recording"}
            </Button>
          </CardTitle>
        </CardHeader>
        <ScrollArea className="flex-1 p-4">
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium text-[#8CC63F] mb-4">
                Ask Patient: Are you experiencing any visual disturbances with your headaches?
              </h3>
              <div className="flex gap-4">
                <Button
                  className="w-32 bg-[#8CC63F] hover:bg-[#7AB52F]"
                >
                  Yes
                </Button>
                <Button
                  variant="outline"
                  className="w-32 border-[#8CC63F] text-[#8CC63F] hover:bg-[#8CC63F] hover:text-white"
                >
                  No
                </Button>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium text-[#8CC63F] mb-4">
                Ask Patient: Do these headaches affect your daily activities?
              </h3>
              <div className="flex gap-4">
                <Button
                  className="w-32 bg-[#8CC63F] hover:bg-[#7AB52F]"
                >
                  Yes
                </Button>
                <Button
                  variant="outline"
                  className="w-32 border-[#8CC63F] text-[#8CC63F] hover:bg-[#8CC63F] hover:text-white"
                >
                  No
                </Button>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium text-[#8CC63F] mb-4">
                Ask Patient: Have you tried any over-the-counter medications?
              </h3>
              <div className="flex gap-4">
                <Button
                  className="w-32 bg-[#8CC63F] hover:bg-[#7AB52F]"
                >
                  Yes
                </Button>
                <Button
                  variant="outline"
                  className="w-32 border-[#8CC63F] text-[#8CC63F] hover:bg-[#8CC63F] hover:text-white"
                >
                  No
                </Button>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium text-[#8CC63F]">
                Inform Patient: Based on your symptoms, we'll need to schedule some tests
              </h3>
              <Button
                variant="outline"
                className="w-32 mt-4 border-[#8CC63F] text-[#8CC63F] hover:bg-[#8CC63F] hover:text-white"
              >
                Complete
              </Button>
            </div>

            <div>
              <h3 className="text-lg font-medium text-[#8CC63F]">
                Inform Patient: EMR has been updated with today's symptoms
              </h3>
              <Button
                variant="outline"
                className="w-32 mt-4 border-[#8CC63F] text-[#8CC63F] hover:bg-[#8CC63F] hover:text-white"
              >
                Complete
              </Button>
            </div>
          </div>
        </ScrollArea>
      </Card>

      {/* Conversation Transcription Panel */}
      <Card className="h-full">
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Stethoscope className="h-5 w-5" />
            Conversation Transcription
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ScrollArea className="h-[calc(100vh-12rem)]">
            <div className="space-y-4">
              <div className="space-y-2">
                <h4 className="text-sm font-semibold">Doctor</h4>
                <p className="text-sm text-muted-foreground">
                  Hello Mr. Doe, how are you feeling today?
                </p>
              </div>
              <div className="space-y-2">
                <h4 className="text-sm font-semibold">Patient</h4>
                <p className="text-sm text-muted-foreground">
                  Hi Dr. Smith, I've been experiencing headaches for the past few days.
                </p>
              </div>
              <div className="space-y-2">
                <h4 className="text-sm font-semibold">Doctor</h4>
                <p className="text-sm text-muted-foreground">
                  I'm sorry to hear that. Can you describe the type of headache and when it typically occurs?
                </p>
              </div>
              <div className="space-y-2">
                <h4 className="text-sm font-semibold">Patient</h4>
                <p className="text-sm text-muted-foreground">
                  It's a throbbing pain, usually on one side of my head. It tends to start in the afternoon and gets worse as the day goes on.
                </p>
              </div>
              <div className="space-y-2">
                <h4 className="text-sm font-semibold">Doctor</h4>
                <p className="text-sm text-muted-foreground">
                  I see. Have you noticed any triggers or patterns? For example, does it happen after certain activities or meals?
                </p>
              </div>
              <div className="space-y-2">
                <h4 className="text-sm font-semibold">Patient</h4>
                <p className="text-sm text-muted-foreground">
                  Now that you mention it, I've been working longer hours lately and staring at my computer screen more. Could that be related?
                </p>
              </div>
            </div>
          </ScrollArea>
        </CardContent>
      </Card>
    </div>
  )
}

