import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const mockData = {
  patient: {
    name: "Jane Smith",
    avatar: "/placeholder-user.jpg",
    dataSource: "AI-generated summary from dental records and images"
  },
  diagnosis: {
    primary: "Dental Fluorosis",
    severity: "Moderate",
    confidence: 80,
    diagnosisMethod: "Diagnosed based on dental imaging and patient history",
    tags: ["Enamel Discoloration", "Surface Irregularities", "Pitting"],
    description: "Dental fluorosis is a developmental disturbance of dental enamel caused by excessive exposure to high concentrations of fluoride during tooth development. This patient's case shows moderate severity, characterized by noticeable discoloration and some surface irregularities.",
    keyObservations: [
      "Visible white spots and streaks on tooth enamel",
      "Some areas of light brown discoloration",
      "Mild pitting observed on several teeth",
      "Enamel appears mottled but maintains overall integrity"
    ]
  },
  detailedFindings: {
    characteristics: [
      "White opaque areas on tooth surfaces",
      "Staining ranging from yellow to dark brown",
      "Pitting observed on 30% of affected teeth",
      "Striations present on anterior teeth"
    ],
    distribution: [
      "Bilateral symmetrical pattern",
      "Most prominent on permanent incisors and first molars",
      "Affects 75% of visible tooth surfaces"
    ],
    associatedFindings: [
      "Mild dental hypersensitivity reported",
      "No significant impact on tooth function observed",
      "Slight increase in caries susceptibility noted"
    ]
  },
  treatmentPlan: {
    immediate: [
      "Professional teeth cleaning and polishing",
      "Topical fluoride application for remineralization",
      "Desensitizing agent application for hypersensitivity"
    ],
    aesthetic: [
      "Micro-abrasion for superficial stains",
      "Teeth whitening to improve overall appearance",
      "Dental bonding for severe pitting (select cases)"
    ],
    longTerm: [
      "Bi-annual dental check-ups and cleanings",
      "Regular fluoride level monitoring in drinking water",
      "Patient education on proper oral hygiene techniques",
      "Consideration of veneers for severe cases in the future"
    ]
  }
}